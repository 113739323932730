import type { AnalyzedMetrics } from '../../../types/automatic';

const member2Metrics: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.35,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData1: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics,
};

const member2Metrics_2: AnalyzedMetrics[] = [
  ...member2Metrics,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData2: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_2,
};

const member2Metrics_3: AnalyzedMetrics[] = [
  ...member2Metrics_2,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData3: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_3,
};

const member2Metrics_4: AnalyzedMetrics[] = [
  ...member2Metrics_3,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData4: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_4,
};

const member2Metrics_5: AnalyzedMetrics[] = [
  ...member2Metrics_4,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData5: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_5,
};

const member2Metrics_6: AnalyzedMetrics[] = [
  ...member2Metrics_5,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData6: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_6,
};

const member2Metrics_7: AnalyzedMetrics[] = [
  ...member2Metrics_6,
  {
    rackNumber: 1,
    rep: 7,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData7: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_7,
};

const member2Metrics_8: AnalyzedMetrics[] = [
  ...member2Metrics_7,
  {
    rackNumber: 1,
    rep: 8,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData8: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_8,
};

const member2Metrics_9: AnalyzedMetrics[] = [
  ...member2Metrics_8,
  {
    rackNumber: 1,
    rep: 9,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData9: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_9,
};

const member2Metrics_10: AnalyzedMetrics[] = [
  ...member2Metrics_9,
  {
    rackNumber: 1,
    rep: 10,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData10: Record<string, AnalyzedMetrics[]> = {
  '2': member2Metrics_10,
};

const member1Metrics: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData11: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics,
  '2': member2Metrics_10,
};

const member1Metrics_2: AnalyzedMetrics[] = [
  ...member1Metrics,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData12: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_2,
  '2': member2Metrics_10,
};

const member1Metrics_3: AnalyzedMetrics[] = [
  ...member1Metrics_2,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData13: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_3,
  '2': member2Metrics_10,
};

const member1Metrics_4: AnalyzedMetrics[] = [
  ...member1Metrics_3,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData14: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_4,
  '2': member2Metrics_10,
};

const member1Metrics_5: AnalyzedMetrics[] = [
  ...member1Metrics_4,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData15: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_5,
  '2': member2Metrics_10,
};

const member1Metrics_6: AnalyzedMetrics[] = [
  ...member1Metrics_5,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData16: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_6,
  '2': member2Metrics_10,
};

const member1Metrics_7: AnalyzedMetrics[] = [
  ...member1Metrics_6,
  {
    rackNumber: 1,
    rep: 7,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData17: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_7,
  '2': member2Metrics_10,
};

const member1Metrics_8: AnalyzedMetrics[] = [
  ...member1Metrics_7,
  {
    rackNumber: 1,
    rep: 8,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData18: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_10,
};

const member2Metrics_11: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData19: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_11,
};

const member2Metrics_12: AnalyzedMetrics[] = [
  ...member2Metrics_11,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData20: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_12,
};

const member2Metrics_13: AnalyzedMetrics[] = [
  ...member2Metrics_12,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData21: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_13,
};

const member2Metrics_14: AnalyzedMetrics[] = [
  ...member2Metrics_13,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData22: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_14,
};

const member2Metrics_15: AnalyzedMetrics[] = [
  ...member2Metrics_14,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData23: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_15,
};

const member2Metrics_16: AnalyzedMetrics[] = [
  ...member2Metrics_15,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData24: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_16,
};

const member2Metrics_17: AnalyzedMetrics[] = [
  ...member2Metrics_16,
  {
    rackNumber: 1,
    rep: 7,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData25: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_17,
};

const member2Metrics_18: AnalyzedMetrics[] = [
  ...member2Metrics_17,
  {
    rackNumber: 1,
    rep: 8,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.1,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData26: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
};

const member3Metrics: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData27: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics,
};

const member3Metrics_2: AnalyzedMetrics[] = [
  ...member3Metrics,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData28: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics_2,
};

const member3Metrics_3: AnalyzedMetrics[] = [
  ...member3Metrics_2,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData29: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics_3,
};

const member3Metrics_4: AnalyzedMetrics[] = [
  ...member3Metrics_3,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData30: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics_4,
};

const member3Metrics_5: AnalyzedMetrics[] = [
  ...member3Metrics_4,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData31: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics_5,
};

const member3Metrics_6: AnalyzedMetrics[] = [
  ...member3Metrics_5,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData32: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_8,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_9: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 1.45,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData33: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_9,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_10: AnalyzedMetrics[] = [
  ...member1Metrics_9,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData34: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_10,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_11: AnalyzedMetrics[] = [
  ...member1Metrics_10,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData35: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_11,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_12: AnalyzedMetrics[] = [
  ...member1Metrics_11,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData36: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_12,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_13: AnalyzedMetrics[] = [
  ...member1Metrics_12,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData37: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_13,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_14: AnalyzedMetrics[] = [
  ...member1Metrics_13,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData38: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_14,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_15: AnalyzedMetrics[] = [
  ...member1Metrics_14,
  {
    rackNumber: 1,
    rep: 7,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData39: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_15,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member1Metrics_16: AnalyzedMetrics[] = [
  ...member1Metrics_15,
  {
    rackNumber: 1,
    rep: 8,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '1',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData40: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_18,
  '3': member3Metrics_6,
};

const member2Metrics_19: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData41: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_19,
  '3': member3Metrics_6,
};

const member2Metrics_20: AnalyzedMetrics[] = [
  ...member2Metrics_19,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData42: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_20,
  '3': member3Metrics_6,
};

const member2Metrics_21: AnalyzedMetrics[] = [
  ...member2Metrics_20,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData43: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_21,
  '3': member3Metrics_6,
};

const member2Metrics_22: AnalyzedMetrics[] = [
  ...member2Metrics_21,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData44: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_22,
  '3': member3Metrics_6,
};

const member2Metrics_23: AnalyzedMetrics[] = [
  ...member2Metrics_22,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData45: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_23,
  '3': member3Metrics_6,
};

const member2Metrics_24: AnalyzedMetrics[] = [
  ...member2Metrics_23,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '2',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 3,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData46: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '2': member2Metrics_24,
  '3': member3Metrics_6,
};

const member3Metrics_7: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData47: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_7,
};

const member3Metrics_8: AnalyzedMetrics[] = [
  ...member3Metrics_7,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData48: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_8,
};

const member3Metrics_9: AnalyzedMetrics[] = [
  ...member3Metrics_8,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData49: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_9,
};

const member3Metrics_10: AnalyzedMetrics[] = [
  ...member3Metrics_9,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 2.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData50: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_10,
};

const member3Metrics_11: AnalyzedMetrics[] = [
  ...member3Metrics_10,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 1.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData51: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_11,
};

const member3Metrics_12: AnalyzedMetrics[] = [
  ...member3Metrics_11,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '3',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 2,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData52: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
};

const member4Metrics: AnalyzedMetrics[] = [
  {
    rackNumber: 1,
    rep: 1,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData53: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics,
};

const member4Metrics_2: AnalyzedMetrics[] = [
  ...member4Metrics,
  {
    rackNumber: 1,
    rep: 2,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData54: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics_2,
};

const member4Metrics_3: AnalyzedMetrics[] = [
  ...member4Metrics_2,
  {
    rackNumber: 1,
    rep: 3,
    up: false,
    down: false,
    upDuration: 0.45,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData55: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics_3,
};

const member4Metrics_4: AnalyzedMetrics[] = [
  ...member4Metrics_3,
  {
    rackNumber: 1,
    rep: 4,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData56: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics_4,
};

const member4Metrics_5: AnalyzedMetrics[] = [
  ...member4Metrics_4,
  {
    rackNumber: 1,
    rep: 5,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData57: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics_5,
};

const member4Metrics_6: AnalyzedMetrics[] = [
  ...member4Metrics_5,
  {
    rackNumber: 1,
    rep: 6,
    up: false,
    down: false,
    upDuration: 0.55,
    downDuration: 1.12,
    memberId: '4',
    exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
    preset: {
      sets: 3,
      performedSets: 1,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  },
];

const liveRackData58: Record<string, AnalyzedMetrics[]> = {
  '1': member1Metrics_16,
  '3': member3Metrics_12,
  '4': member4Metrics_6,
};

export const rack_1_dynamic_data_mock = [
  liveRackData1,
  liveRackData2,
  liveRackData3,
  liveRackData4,
  liveRackData5,
  liveRackData6,
  liveRackData7,
  liveRackData8,
  liveRackData9,
  liveRackData10,
  liveRackData11,
  liveRackData12,
  liveRackData13,
  liveRackData14,
  liveRackData15,
  liveRackData16,
  liveRackData17,
  liveRackData18,
  liveRackData19,
  liveRackData20,
  liveRackData21,
  liveRackData22,
  liveRackData23,
  liveRackData24,
  liveRackData25,
  liveRackData26,
  liveRackData27,
  liveRackData28,
  liveRackData29,
  liveRackData30,
  liveRackData31,
  liveRackData32,
  liveRackData33,
  liveRackData34,
  liveRackData35,
  liveRackData36,
  liveRackData37,
  liveRackData38,
  liveRackData39,
  liveRackData40,
  liveRackData41,
  liveRackData42,
  liveRackData43,
  liveRackData44,
  liveRackData45,
  liveRackData46,
  liveRackData47,
  liveRackData48,
  liveRackData49,
  liveRackData50,
  liveRackData51,
  liveRackData52,
  liveRackData53,
  liveRackData54,
  liveRackData55,
  liveRackData56,
  liveRackData57,
  liveRackData58,
];
