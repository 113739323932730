import type { AnalyzedMetrics } from '../../../types/automatic';

// Helper functions
function randInRange(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function generateRep(
  memberId: string,
  performedSets: number,
  repNumber: number,
  base: Partial<AnalyzedMetrics>,
): AnalyzedMetrics {
  return {
    ...base,
    memberId,
    rep: repNumber,
    upDuration: randInRange(0.1, 1.5),
    downDuration: randInRange(0.1, 3),
    preset: { ...base.preset, performedSets },
  };
}

/**
 * Generates a sequence of incremental updates (Record<string, AnalyzedMetrics[]>) for one user performing a set of 10 reps.
 * Each update increments the data by one rep.
 * @param memberId User's memberId
 * @param performedSets The performedSets number
 * @param base Base partial metrics
 * @param startingIndex The update index offset (not strictly necessary, but we may keep track if needed)
 * @returns An array of updates, each is Record<string, AnalyzedMetrics[]>
 */
function generateUserSetUpdates(
  memberId: string,
  performedSets: number,
  base: Partial<AnalyzedMetrics>,
): Record<string, AnalyzedMetrics[]>[] {
  const updates: Record<string, AnalyzedMetrics[]>[] = [];
  let metrics: AnalyzedMetrics[] = [];
  for (let r = 1; r <= 10; r++) {
    metrics = [...metrics, generateRep(memberId, performedSets, r, base)];
    updates.push({ [memberId]: metrics });
  }
  return updates;
}

/**
 * The scenario:
 * User 5 performs set 1 (10 reps), then user 6 performs set 1 (10 reps), then user 7 set 1 (10 reps),
 * then user 5 set 2 (10 reps), user 6 set 2 (10 reps), user 7 set 2 (10 reps),
 * then user 5 set 3 (10 reps), user 6 set 3 (10 reps), user 7 set 3 (10 reps).
 * This simulates the pattern from the original code.
 */

const baseRep: Partial<AnalyzedMetrics> = {
  rackNumber: 22,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 60,
    reps: 10,
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

const baseRep2: Partial<AnalyzedMetrics> = {
  rackNumber: 22,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 150,
    reps: 10,
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

const baseRep3: Partial<AnalyzedMetrics> = {
  rackNumber: 22,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 220,
    reps: 10,
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

const sequence = [
  // PerformedSets = 1
  ...generateUserSetUpdates('5', 1, baseRep),
  ...generateUserSetUpdates('6', 1, baseRep2),
  ...generateUserSetUpdates('7', 1, baseRep3),

  // PerformedSets = 2
  ...generateUserSetUpdates('5', 2, baseRep),
  ...generateUserSetUpdates('6', 2, baseRep2),
  ...generateUserSetUpdates('7', 2, baseRep3),

  // PerformedSets = 3
  ...generateUserSetUpdates('5', 3, baseRep),
  ...generateUserSetUpdates('6', 3, baseRep2),
  ...generateUserSetUpdates('7', 3, baseRep3),
];

// sequence now contains all the increments that were previously hardcoded
export const rack_22_dynamic_data_mock = sequence;
