import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './SetValues.module.scss';
import InputField from '../atoms/InputField';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import TimeInputField from '../atoms/TimeInputField';
import RangeInputField, {
  parseRange,
  formatRange,
} from '../atoms/RangeInputField';

import type { TrainingSet } from '../../types';

export interface SetValuesProps {
  index: number;
  set: TrainingSet;
  updateSet: (newSet: TrainingSet) => void;
  deleteSet: () => void;
  canBeAutomatic: boolean;
}

const EFFORT_OPTIONS = [
  { value: 'NaN', label: 'None' }, // "reset" option
  ...Array.from({ length: 20 }, (_, i) => {
    const effortValue = (i + 1) * 5;
    return {
      value: String(effortValue),
      label: `${effortValue}%`,
    };
  }),
];

const SetValues: React.FC<SetValuesProps> = ({
  index,
  set,
  updateSet,
  deleteSet,
  canBeAutomatic,
}) => {
  const [weight, setWeight] = useState<number | string>(set.weight ?? '');
  const [reps, setReps] = useState<number | string>(set.reps ?? '');
  const [rest, setRest] = useState<number>(set.rest ?? 0);

  const [effort, setEffort] = useState<number | null>(
    typeof set.oneRMPercentage === 'number'
      ? isNaN(set.oneRMPercentage)
        ? null
        : set.oneRMPercentage
      : null,
  );

  // We'll display the numeric value if it exists, else "NaN"
  const effortValue = effort === null ? 'NaN' : String(effort);

  // We store a single “range” string in state for concentric
  const [concentricRange, setConcentricRange] = useState<string>(
    formatRange(
      set.metrics?.minConcentric ?? null,
      set.metrics?.maxConcentric ?? null,
    ),
  );

  // We store a single “range” string in state for eccentric
  const [eccentricRange, setEccentricRange] = useState<string>(
    formatRange(
      set.metrics?.minEccentric ?? null,
      set.metrics?.maxEccentric ?? null,
    ),
  );

  const handleEffortChange = (newVal: string) => {
    const numericVal = parseFloat(newVal);
    if (isNaN(numericVal)) {
      // Means user picked the "NaN" placeholder
      setEffort(null);
      updateSet({ ...set, oneRMPercentage: NaN });
    } else {
      setEffort(numericVal);
      updateSet({ ...set, oneRMPercentage: numericVal });
    }
  };

  // Handle updates for “concentric range”
  const handleConcentricRangeChange = (newRangeValue: string) => {
    setConcentricRange(newRangeValue);
    const { min, max } = parseRange(newRangeValue);
    updateSet({
      ...set,
      metrics: {
        ...set.metrics,
        minConcentric: min,
        maxConcentric: max,
      },
    });
  };

  // Handle updates for “eccentric range”
  const handleEccentricRangeChange = (newRangeValue: string) => {
    setEccentricRange(newRangeValue);
    const { min, max } = parseRange(newRangeValue);
    updateSet({
      ...set,
      metrics: {
        ...set.metrics,
        minEccentric: min,
        maxEccentric: max,
      },
    });
  };

  const handleWeightChange = (newVal: string) => {
    setWeight(newVal);
    updateSet({ ...set, weight: Number(newVal) });
  };

  const handleRepsChange = (newVal: string) => {
    setReps(newVal);
    updateSet({ ...set, reps: Number(newVal) });
  };

  const handleRestChange = (newRest: number) => {
    setRest(newRest);
    updateSet({ ...set, rest: Number(newRest) });
  };

  return (
    <div
      className={classNames(
        styles.setValues,
        canBeAutomatic ? styles.automaticSetProperties : styles.setProperties,
      )}
    >
      <div className={styles.setTitle}>SET {index + 1}</div>

      {/* Weight */}
      <InputField
        placeholder=" - "
        type="number"
        className={styles.setInput}
        inputName={`weight-${index}`}
        value={String(weight)}
        onChange={handleWeightChange}
      />

      {/* Reps */}
      <InputField
        placeholder=" - "
        type="number"
        className={styles.setInput}
        inputName={`reps-${index}`}
        value={String(reps)}
        onChange={handleRepsChange}
      />

      {/* Rest */}
      <TimeInputField
        className={styles.setInput}
        inputName={`rest-${index}`}
        value={rest}
        onBlur={handleRestChange}
      />

      {/*
      EFFORT dropdown in the normal row, for both canBeAutomatic or not.
      Adjust position as desired in the layout.
      */}
      <InputField
        inputName={`effort-${index}`}
        className={styles.dropDownInput}
        type="dropdown"
        placeholder="%1RM"
        value={effortValue}
        onChange={handleEffortChange}
        options={EFFORT_OPTIONS} // critical for dropdown
      />

      {/** If canBeAutomatic = true => show 2 range inputs */}
      {canBeAutomatic && (
        <>
          {/* Concentric Range */}
          <RangeInputField
            placeholder="-"
            value={concentricRange}
            onChange={handleConcentricRangeChange}
            className={styles.metricInput}
          />

          {/* Eccentric Range */}
          <RangeInputField
            placeholder="-"
            value={eccentricRange}
            onChange={handleEccentricRangeChange}
            className={styles.metricInput}
          />
        </>
      )}

      {/* Delete set */}
      <span onClick={deleteSet} className={styles.deleteSet}>
        <DeleteForeverOutlinedIcon />
      </span>
    </div>
  );
};

SetValues.displayName = 'SetValues';

export default SetValues;
