import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from './GroupSet.module.scss';
import setValuesStyles from './SetValues.module.scss';
import { Button } from '@mui/material';
import SetValues from './SetValues';
import {
  extractExerciseDragData,
  extractExerciseGroupDragData,
} from './exerciseDragUtils';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import { locales, DragTypes, indexToAlphabetMap } from '../../constants';

import type {
  SetGroup,
  Exercise,
  TrainingSet,
  ExerciseGroup,
} from '../../types';
import type { AppState } from '../../index';

const texts = locales.en.components.workouts.groupSet;

export interface GroupSetProps {
  exerciseGroup: ExerciseGroup;
  groupSetIndex: number;
  setGroup: SetGroup;
  updateSets: (sets: SetGroup) => void;
  exercises: AppState['exercises'];
  onSetGroupMoveToExistingExerciseGroup: (
    draggedExerciseGroupIndex: number,
    draggedSetGroupIndex: number,
    setGroup: SetGroup,
  ) => void;
  onUnifyExerciseGroups: (
    draggedExerciseGroup: ExerciseGroup,
    draggedExerciseGroupIndex: number,
    targetExerciseGroupIndex: number,
  ) => void;
  onExerciseDrop: (
    exercise: Exercise,
    exerciseGroupIndexToRemove?: number,
    groupIndexToRemove?: number,
  ) => void;
  exerciseGroupIndex: number;
  onReorderGroupSet: (draggedIndex: number, targetIndex: number) => void;
  enableInternalDrag: boolean;
}

const setProperties = ['WEIGHT (LBS)', 'REPS', 'REST', 'EFFORT'];
const metrics = ['CONCENTRIC', 'ECCENTRIC'];

const GroupSet: React.FC<GroupSetProps> = ({
  exerciseGroup,
  setGroup,
  exercises,
  exerciseGroupIndex,
  groupSetIndex,
  onSetGroupMoveToExistingExerciseGroup,
  onUnifyExerciseGroups,
  onExerciseDrop,
  updateSets,
  onReorderGroupSet,
  enableInternalDrag,
}) => {
  const exercise = exercises[setGroup[0].exerciseId];
  if (!exercise) {
    throw new Error(
      `Exercises not found. exerciseId: ${setGroup[0].exerciseId}`,
    );
  }
  const [dropZoneVisible, setDropZoneVisible] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const draggedSetGroupIndexRef = React.useRef<number | null>(null);

  const [renderKey, setRenderKey] = useState(0); // Add renderKey to force re-render on updates
  const properties = exercise.canBeAutomatic
    ? setProperties.concat(metrics)
    : setProperties;
  useEffect(() => {
    // Use an effect to force a re-render when setGroup change
    setRenderKey((prevKey) => prevKey + 1); // Change key to force re-render
  }, [setGroup.length]);

  const handleDropInsideExerciseGroup = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('application/json');
    const parsedData = JSON.parse(data);
    const draggedExerciseGroupIndex = parseInt(
      parsedData.exerciseGroupIndex,
      10,
    );
    const draggedSetGroupIndex = parseInt(parsedData.groupSetIndex, 10);
    const isSameExerciseGroup =
      draggedExerciseGroupIndex === exerciseGroupIndex;

    switch (parsedData.type) {
      case DragTypes.GroupSetReorder:
        if (isSameExerciseGroup) {
          onReorderGroupSet(draggedSetGroupIndex, groupSetIndex + 1);
        } else {
          const setGroup = parsedData.setGroup;
          onSetGroupMoveToExistingExerciseGroup(
            draggedExerciseGroupIndex,
            draggedSetGroupIndex,
            setGroup,
          );
        }
        break;
      case DragTypes.ExerciseGroupReorder:
        if (!isSameExerciseGroup) {
          const draggedExerciseGroup = extractExerciseGroupDragData(e);
          onUnifyExerciseGroups(
            draggedExerciseGroup,
            draggedExerciseGroupIndex,
            exerciseGroupIndex,
          );
        }
        break;
      case DragTypes.DragExercise:
        const exercise = extractExerciseDragData(e);
        onExerciseDrop(exercise);
        break;
      default:
        console.error(`unknown ${parsedData.type} drag type`);
        break;
    }
    draggedSetGroupIndexRef.current = null;
    setDropZoneVisible(false);
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedSetGroupIndexRef.current === groupSetIndex) {
      return;
    }
    setDropZoneVisible(true);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedSetGroupIndexRef.current === groupSetIndex) {
      return;
    }
    setDropZoneVisible(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropZoneVisible(false);
  };

  const addSet = () => {
    const newSet = _.last(setGroup);
    if (!newSet) {
      throw new Error('No sets defined.');
    }
    const updatedSets = [...setGroup, { ...newSet }];
    updateSets(updatedSets);
  };

  const updateSet = (updatedSet: TrainingSet, setIndex: number) => {
    const updatedSets = setGroup.map((set, index) =>
      index === setIndex ? updatedSet : set,
    );
    updateSets(updatedSets);
  };

  const handleDeleteSet = (index: number) => {
    const updatedSets = setGroup.filter((_, i) => i !== index);
    updateSets([...updatedSets]);
  };

  const handleSetGroupDragStart = (e: React.DragEvent) => {
    const dragType = enableInternalDrag
      ? DragTypes.GroupSetReorder
      : DragTypes.ExerciseGroupReorder;
    const draggedItemData = {
      type: dragType,
      exerciseGroupIndex,
      groupSetIndex,
      exercise,
      setGroup,
      exerciseGroup,
    };
    e.dataTransfer.setData('application/json', JSON.stringify(draggedItemData));
    draggedSetGroupIndexRef.current = groupSetIndex;
  };

  const handleDragEnd = () => {
    draggedSetGroupIndexRef.current = null;
  };

  return (
    <div
      key={renderKey}
      className={styles.groupSet}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={(e) => {
        if (dropZoneVisible) {
          handleDropInsideExerciseGroup(e);
        }
      }}
    >
      <div
        className={classNames(styles.groupSetContent, {
          [styles.opened]: isOpen,
          [styles.circuit]: enableInternalDrag,
        })}
      >
        <div
          className={styles.exerciseImage}
          style={{
            backgroundImage: `url(${exercise.imageUrl})`,
          }}
        />
        <div className={styles.groupDetails}>
          <div
            onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen)}
            className={classNames(styles.groupDetailsHeader, {
              [styles.withContent]: isOpen,
            })}
          >
            <div className={styles.groupDetailsTitle}>
              <span
                className={styles.exerciseNumber}
              >{`${indexToAlphabetMap[exerciseGroupIndex]}${groupSetIndex + 1}.`}</span>
              <div className={styles.groupDetailsIcon}>
                {isOpen ? (
                  <KeyboardArrowDownOutlinedIcon color="primary" />
                ) : (
                  <KeyboardArrowUpOutlinedIcon color="primary" />
                )}
              </div>
              <span className={styles.exerciseName}>{exercise.name}</span>
              <div
                draggable
                onDragStart={handleSetGroupDragStart}
                onDragEnd={handleDragEnd}
                className={styles.dragGroupSet}
              >
                <OpenWithOutlinedIcon color="info" />
              </div>
              {/* )} */}
            </div>
          </div>
          {isOpen && (
            <>
              <div className={styles.sets}>
                <div
                  className={classNames(
                    exercise.canBeAutomatic
                      ? setValuesStyles.automaticSetProperties
                      : setValuesStyles.setProperties,
                  )}
                >
                  <span className={styles.keepSpanForEmptyEl} />
                  {properties.map((property) => (
                    <span className={styles.setProperty} key={property}>
                      {property}
                    </span>
                  ))}
                  <span className={styles.keepSpanForEmptyEl} />
                </div>
                {setGroup.map((set, index) => (
                  <SetValues
                    index={index}
                    set={set}
                    updateSet={(newSet) => updateSet(newSet, index)}
                    key={`${groupSetIndex}-setValues-${index}-${set.exerciseId}`}
                    deleteSet={() => handleDeleteSet(index)}
                    canBeAutomatic={exercise.canBeAutomatic || false}
                  />
                ))}
              </div>
              <div onClick={addSet} className={styles.addSet}>
                <Button className={styles.addSetButton}>
                  <AddBoxOutlinedIcon />
                  Add Set
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        className={classNames(styles.dropZone, {
          [styles.dropZoneVisible]: dropZoneVisible,
        })}
      >
        <div className={styles.dropZoneTitleWrapper}>
          <SaveAltIcon color="inherit" />
          <span className={styles.dropZoneTitle}>
            {texts.groupSetReorderDropZone}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GroupSet;
