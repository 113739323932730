import _ from 'lodash';
import React, { useCallback, useRef, useState, useEffect } from 'react';
import styles from './EditTemplate.module.scss';
import classNames from 'classnames';
import EmptyState from '../composites/EmptyState';
import FitnessCenterOutlinedIcon from '@mui/icons-material/FitnessCenterOutlined';
import SearchBar from '../atoms/SearchBar';
import ExerciseThumbnail from '../composites/ExerciseThumbnail';
import IconWithText from '../atoms/IconWithText';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import ExerciseGroupComponent from './ExerciseGroup';
import { extractExerciseDragData } from './exerciseDragUtils';
import {
  calculateRoutineDurationMin,
  createTrainingSetFromExercise,
  getTrainerMergedExercises,
} from '../../utils/dashboardUtils';
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@mui/material';
import { getNumOfRoutineExercises } from '../../utils/dashboardUtils';
import { locales, DragTypes } from '../../constants';
import { useGlobalData, useLoginData } from '../../providers';

import type {
  Exercise,
  ExerciseGroup,
  TrainingRoutineTemplate,
  WithOptionalId,
  SetGroup,
} from '../../types';

const texts = locales.en.components.workouts.editTemplate;

export interface EditTemplateDialogProps {
  onSubmit: (
    template: WithOptionalId<TrainingRoutineTemplate>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  template: WithOptionalId<TrainingRoutineTemplate>;
  setTemplate: React.Dispatch<
    React.SetStateAction<WithOptionalId<TrainingRoutineTemplate>>
  >;
  onClose: () => void;
}

const EXERCISES_PER_SCROLL = 8;

const EditTemplate: React.FC<EditTemplateDialogProps> = ({
  onClose,
  onSubmit,
  template,
  setTemplate,
}) => {
  const { trainerData } = useLoginData();
  const { exercises: globalExercises } = useGlobalData();
  const trainerMergedExercises = getTrainerMergedExercises(
    Object.values(globalExercises),
    trainerData?.personalizedExercises || [],
  );
  const trainer = trainerData?.trainer;
  const [loading, setLoading] = useState(false);
  const [editDescriptionActive, setEditDescriptionActive] = useState(false);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const [editNameActive, setEditNameActive] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const exerciseListRef = useRef<HTMLDivElement>(null);
  const [description, setDescription] = useState(
    template.routine.description || '',
  );
  const [name, setName] = useState(template.routine.name || '');
  const [nameError, setNameError] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [dropZoneVisible, setDropZoneVisible] = useState(false);
  const [numOfExercisesToShow, setNumOfExercisesToShow] =
    useState<number>(EXERCISES_PER_SCROLL);
  const trainerId = trainer?.id;

  const filteredExercises = trainerMergedExercises
    .filter(({ name, imageUrl }) => name && imageUrl)
    .filter(({ name }) =>
      name.toLowerCase().includes(searchQuery.toLowerCase()),
    )
    .sort((a, b) => {
      if (a.trainerId === trainerId && b.trainerId !== trainerId) return -1;
      if (a.trainerId !== trainerId && b.trainerId === trainerId) return 1;
      return a.name.localeCompare(b.name);
    });

  const handleExercisesScroll = useCallback(
    _.debounce(() => {
      const exerciseListElement = exerciseListRef.current;
      if (!exerciseListElement) return;
      const scrollHeight = exerciseListElement.scrollHeight;
      const currentScroll = exerciseListElement.scrollTop;
      const clientHeight = exerciseListElement.clientHeight;
      const currentRatio = currentScroll / (scrollHeight - clientHeight);
      if (currentRatio > 0.8) {
        setNumOfExercisesToShow((prevNumOfExercisesToShow) =>
          Math.min(
            filteredExercises.length,
            prevNumOfExercisesToShow + EXERCISES_PER_SCROLL,
          ),
        );
      }
    }, 300),
    [],
  );

  useEffect(() => {
    nameRef.current?.focus();
  }, []);

  const getTemplateInfos = () => {
    return [
      {
        text: `${calculateRoutineDurationMin(template.routine)} min`,
        icon: <AccessTimeOutlinedIcon color="primary" />,
      },
      {
        text: `${getNumOfRoutineExercises(template.routine)} exercises`,
        icon: <FitnessCenterOutlinedIcon color="primary" />,
      },
    ];
  };

  const updateExerciseGroup = (
    updatedExerciseGroup: ExerciseGroup,
    groupIndex: number,
  ) => {
    setTemplate((prevTemplate) => {
      const exerciseGroups = prevTemplate.routine.exerciseGroups
        .map((group, index) =>
          groupIndex === index ? updatedExerciseGroup : group,
        )
        .filter(({ sets }) => sets.length > 0);
      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups,
        },
      };
    });
  };

  const handleSetGroupDropFromExerciseGroup = (
    droppedExercise: Exercise,
    exerciseGroupIndexToRemove: number,
    groupIndexToRemove: number,
    newExerciseGroupIndex: number,
    setGroup: SetGroup,
  ) => {
    setTemplate((prevTemplate) => {
      const newExerciseGroup = {
        name: droppedExercise.name,
        sets: [[...setGroup]],
      };
      const updatedExerciseGroups = prevTemplate.routine.exerciseGroups.map(
        (group, i) =>
          exerciseGroupIndexToRemove === i
            ? {
                ...group,
                sets: group.sets.filter(
                  (_, index) => index !== groupIndexToRemove,
                ),
              }
            : group,
      );
      const newExerciseGroups = [
        ...updatedExerciseGroups.slice(0, newExerciseGroupIndex),
        newExerciseGroup,
        ...updatedExerciseGroups.slice(newExerciseGroupIndex),
      ];

      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: newExerciseGroups,
        },
      };
    });
  };

  const handleSetGroupMoveToExistingExerciseGroup = (
    newExerciseGroupIndex: number,
    draggedExerciseGroupIndex: number,
    draggedSetGroupIndex: number,
    draggedSetGroup: SetGroup,
  ) => {
    setTemplate((prevTemplate) => {
      const updatedExerciseGroups = prevTemplate.routine.exerciseGroups.map(
        (group, i) =>
          draggedExerciseGroupIndex === i
            ? {
                ...group,
                sets: group.sets.filter(
                  (_, index) => index !== draggedSetGroupIndex,
                ),
              }
            : newExerciseGroupIndex === i
              ? {
                  ...group,
                  sets: [...group.sets, draggedSetGroup],
                }
              : group,
      );

      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: updatedExerciseGroups,
        },
      };
    });
  };

  const handleUnifyExerciseGroups = (
    draggedExerciseGroup: ExerciseGroup,
    draggedExerciseGroupIndex: number,
    targetExerciseGroupIndex: number,
  ) => {
    setTemplate((prevTemplate) => {
      const updatedExerciseGroups = prevTemplate.routine.exerciseGroups.map(
        (group, i) =>
          draggedExerciseGroupIndex === i
            ? {
                ...group,
                sets: [],
              }
            : targetExerciseGroupIndex === i
              ? {
                  ...group,
                  sets: [...group.sets, ...draggedExerciseGroup.sets],
                }
              : group,
      );

      const newExerciseGroups = updatedExerciseGroups.filter(
        ({ sets }) => sets.length > 0,
      );

      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: newExerciseGroups,
        },
      };
    });
  };

  const handleDropExerciseOnExerciseGroup = (
    exercise: Exercise,
    indexToInsertExerciseGroup: number,
  ) => {
    // needs to add a new exercise group with the exercise to the routine in the specified index. If the index is -1, it should add the exercise group to the end of the routine. if the index location already contains an exercise group, push this new one bwteen the two.
    setTemplate((prevTemplate) => {
      const newExerciseGroup = {
        name: exercise.name,
        sets: [[createTrainingSetFromExercise(exercise)]],
      };
      const newExerciseGroups = [
        ...prevTemplate.routine.exerciseGroups.slice(
          0,
          indexToInsertExerciseGroup,
        ),
        newExerciseGroup,
        ...prevTemplate.routine.exerciseGroups.slice(
          indexToInsertExerciseGroup,
        ),
      ];
      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: newExerciseGroups,
        },
      };
    });
  };

  const handleExerciseDropOnSets = (
    droppedExercise: Exercise,
    exerciseGroupIndex: number,
    exerciseGroupIndexToRemove?: number,
    groupIndexToRemove?: number,
  ) => {
    setTemplate((prevTemplate) => {
      const newTrainingSet = createTrainingSetFromExercise(droppedExercise);
      const updatedExerciseGroups = prevTemplate.routine.exerciseGroups.map(
        (group, i) =>
          exerciseGroupIndex === i
            ? {
                ...group,
                sets: [...group.sets, [newTrainingSet]],
              }
            : group,
      );
      if (
        exerciseGroupIndexToRemove !== undefined &&
        !groupIndexToRemove &&
        groupIndexToRemove !== 0
      ) {
        const newExerciseGroups = updatedExerciseGroups.filter(
          (_, i) => i !== exerciseGroupIndexToRemove,
        );
        return {
          ...prevTemplate,
          routine: {
            ...prevTemplate.routine,
            exerciseGroups: newExerciseGroups,
          },
        };
      }
      if (
        groupIndexToRemove !== undefined &&
        exerciseGroupIndexToRemove !== undefined
      ) {
        updatedExerciseGroups[exerciseGroupIndexToRemove] = {
          ...updatedExerciseGroups[exerciseGroupIndexToRemove],
          sets: updatedExerciseGroups[exerciseGroupIndexToRemove].sets.filter(
            (_, index) => index !== groupIndexToRemove,
          ),
        };
      }
      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: updatedExerciseGroups,
        },
      };
    });
  };

  const handleDropOnRoutine = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const data = e.dataTransfer.getData('application/json');
    const parsedData = JSON.parse(data);

    switch (parsedData.type) {
      case DragTypes.GroupSetReorder:
        // TODO: Implement exercise reorder - when dropping existing group set from inside the workout
        break;
      case DragTypes.DragExercise:
        const exercise = extractExerciseDragData(e);
        setTemplate((prevTemplate) => {
          const newExerciseGroup = {
            name: exercise.name,
            sets: [[createTrainingSetFromExercise(exercise)]],
          };
          const newExerciseGroups = [
            ...prevTemplate.routine.exerciseGroups,
            newExerciseGroup,
          ];
          return {
            ...prevTemplate,
            routine: {
              ...prevTemplate.routine,
              exerciseGroups: newExerciseGroups,
            },
          };
        });
        break;
      default:
        console.error(`unknown ${parsedData.type} drag type`);
        break;
    }

    setDropZoneVisible(false);
  };

  const handleDragOverRoutine = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropZoneVisible(true);
  };

  const handleDragOverRoutineEnd = (e: React.DragEvent) => {
    e.preventDefault();
    setDropZoneVisible(false);
  };

  const moveExerciseGroup = (
    groupOriginIndex: number,
    groupTargetIndex: number,
  ) => {
    if (
      !template.routine.exerciseGroups[groupOriginIndex] ||
      groupTargetIndex < 0 ||
      groupTargetIndex >= template.routine.exerciseGroups.length
    ) {
      return;
    }

    if (groupOriginIndex > groupTargetIndex) {
      groupTargetIndex += 1;
    }

    setTemplate((prevTemplate) => {
      const newExerciseGroups = [...prevTemplate.routine.exerciseGroups];
      const [movedGroup] = newExerciseGroups.splice(groupOriginIndex, 1);
      newExerciseGroups.splice(groupTargetIndex, 0, movedGroup);
      return {
        ...prevTemplate,
        routine: {
          ...prevTemplate.routine,
          exerciseGroups: newExerciseGroups,
        },
      };
    });
  };

  const handleSubmit = async () => {
    if (!name) {
      nameRef.current?.focus();
      return setNameError(true);
    }
    setLoading(true);
    const { success, errorMessage } = await onSubmit(template);
    if (success) {
      onClose();
    } else {
      setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const mergedExercisesRecord = _.keyBy(trainerMergedExercises, 'id');

  return (
    <div className={styles.editTemplate}>
      <div
        className={classNames(styles.editTemplateContent, styles.leftContent)}
      >
        <h1 className={styles.title}>Drag to add exercise to workout</h1>
        <SearchBar
          className={styles.editTemplateSearch}
          setSearchQuery={setSearchQuery}
          placeholder="Search Exercises..."
        />
        <div
          onScroll={handleExercisesScroll}
          className={styles.exerciseList}
          ref={exerciseListRef}
        >
          <div className={styles.exercises}>
            {filteredExercises
              .slice(0, numOfExercisesToShow)
              .map((exercise) => (
                <div key={exercise.id} className={styles.exerciseItem}>
                  <ExerciseThumbnail
                    exercise={exercise}
                    onDragStart={(e) => {
                      e.dataTransfer.setData(
                        'application/json',
                        JSON.stringify({
                          exercise,
                          type: DragTypes.DragExercise,
                        }),
                      );
                      e.dataTransfer.effectAllowed = 'move';
                    }}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.editTemplateContent, styles.rightContent)}
      >
        <div className={styles.rightContentHeader}>
          <div className={styles.templateName}>
            <div className={styles.templateNameHeader}>
              <span className={styles.templateNameTitle}>WORKOUT NAME:</span>
              <div
                className={styles.templateNameEdit}
                onClick={() => {
                  setEditNameActive(true);
                  nameRef.current?.select();
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon color="info" />
              </div>
            </div>
            <div className={styles.templateNameContent}>
              <TextField
                fullWidth
                inputRef={nameRef}
                color="primary"
                name="workoutName"
                placeholder={texts.titlePlaceholder}
                value={name}
                className={classNames(styles.nameTextField, {
                  [styles.editedName]: editNameActive,
                  [styles.nameError]: nameError,
                })}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setNameError(false);
                  }
                  setName(e.target.value);
                }}
                onFocus={() => setEditNameActive(true)}
                onBlur={() => {
                  setEditNameActive(false);
                  setTemplate((prevTemplate) => ({
                    ...prevTemplate,
                    name,
                    routine: { ...prevTemplate.routine, name },
                  }));
                }}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: 'white', // Replace with your desired color
                    // fontStyle: 'italic', // Additional styling
                    fontSize: '14px',
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.templateInfos}>
            {getTemplateInfos().map(({ text, icon }) => (
              <IconWithText key={text} icon={icon} text={text} />
            ))}
          </div>
        </div>
        <div
          onDrop={handleDropOnRoutine}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setDropZoneVisible(true);
          }}
          onDragEnter={handleDragOverRoutine}
          onDragLeave={handleDragOverRoutineEnd}
          className={styles.templateContent}
        >
          {template.routine.exerciseGroups.length === 0 ? (
            <EmptyState
              icon={<FitnessCenterOutlinedIcon color="primary" />}
              text="No exercises yet. Add exercises to start building your routine."
            />
          ) : (
            <>
              <div className={styles.templateDescription}>
                <div className={styles.templateDescriptionHeader}>
                  <span className={styles.templateDescriptionTitle}>
                    INSTRUCTIONS:
                  </span>
                  <div
                    className={styles.templateDescriptionEdit}
                    onClick={() => {
                      setEditDescriptionActive(true);
                      descriptionRef.current?.select();
                    }}
                  >
                    <DriveFileRenameOutlineOutlinedIcon color="info" />
                  </div>
                </div>
                <div className={styles.templateDescriptionContent}>
                  <TextField
                    placeholder={texts.descriptionPlaceholder}
                    fullWidth
                    inputRef={descriptionRef}
                    color="primary"
                    name="workoutDescription"
                    value={description}
                    className={classNames(styles.descriptionTextField, {
                      [styles.editedDescription]: editDescriptionActive,
                    })}
                    onChange={(e) => setDescription(e.target.value)}
                    onFocus={() => setEditDescriptionActive(true)}
                    onBlur={() => {
                      setEditDescriptionActive(false);
                      setTemplate((prevTemplate) => ({
                        ...prevTemplate,
                        routine: { ...prevTemplate.routine, description },
                      }));
                    }}
                    sx={{
                      '& .MuiInputBase-input::placeholder': {
                        color: 'white', // Replace with your desired color
                        fontStyle: 'italic', // Additional styling
                        fontSize: '12px',
                      },
                    }}
                  />
                </div>
              </div>
              <div className={styles.templateGroups}>
                {template.routine.exerciseGroups.map((group, index) => (
                  <ExerciseGroupComponent
                    moveExerciseGroup={moveExerciseGroup}
                    trainerMergedExercises={mergedExercisesRecord}
                    key={`group-${index}`}
                    exerciseGroup={group}
                    exerciseGroupIndex={index}
                    onSetGroupMoveToExistingExerciseGroup={(
                      draggedExerciseGroupIndex,
                      draggedSetGroupIndex,
                      setGroup,
                    ) => {
                      handleSetGroupMoveToExistingExerciseGroup(
                        index,
                        draggedExerciseGroupIndex,
                        draggedSetGroupIndex,
                        setGroup,
                      );
                    }}
                    onUnifyExerciseGroups={handleUnifyExerciseGroups}
                    onDropExerciseAfterExerciseGroup={
                      handleDropExerciseOnExerciseGroup
                    }
                    onExerciseDrop={(
                      exercise,
                      exerciseGroupIndexToRemove,
                      groupIndexToRemove,
                    ) =>
                      handleExerciseDropOnSets(
                        exercise,
                        index,
                        exerciseGroupIndexToRemove,
                        groupIndexToRemove,
                      )
                    }
                    onSetGroupDropFromExerciseGroup={
                      handleSetGroupDropFromExerciseGroup
                    }
                    updateExerciseGroup={(updatedExerciseGroup) =>
                      updateExerciseGroup(updatedExerciseGroup, index)
                    }
                  />
                ))}
              </div>
            </>
          )}
          <div
            onDragOver={() => setDropZoneVisible(true)}
            onDragEnter={() => setDropZoneVisible(true)}
            onDragLeave={() => setDropZoneVisible(false)}
            className={classNames(styles.dropZone, {
              [styles.dropZoneVisible]: dropZoneVisible,
            })}
          >
            <span className={styles.dropZoneTitle}>
              Add Exercise To Workout
            </span>
          </div>
        </div>
        <div className={styles.rightContentFooter}>
          <Button
            className={classNames(styles.submit, styles.action)}
            onClick={handleSubmit}
            disabled={loading || template.routine.exerciseGroups.length === 0}
          >
            {loading ? (
              <CircularProgress size={24} color="info" />
            ) : (
              <span className={styles.submitText}>Save Workout</span>
            )}
          </Button>
          <Button
            className={classNames(styles.cancel, styles.action)}
            onClick={onClose}
            disabled={loading}
          >
            <span className={styles.cancelText}>Cancel</span>
          </Button>
        </div>
      </div>
      {errorMessage && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => setErrorMessage('')}
        >
          <Alert onClose={() => setErrorMessage('')} severity="error">
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default EditTemplate;
