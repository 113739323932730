import React, { useState, useEffect } from 'react';
import styles from './UserWorkoutsRow.module.scss';
import classNames from 'classnames';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { getFullName, calculateUserStatistics } from '../../utils';
import Menu from '../atoms/Menu';
import VelocityPie from './VelocityPie';
import ConfirmationDialog from '../DialogWrappers/ConfirmationDialog';
import AssignProgramOptionsDialog from '../Programs/AssignProgramOptionsDialog';
import AssignProgramDialog from '../Programs/AssignProgramDialog';
import { assignProgramToMembers } from '../../api';
import { UserType } from '../../constants';
import { useRefreshState } from '../../providers';
import { locales } from '../../constants/locale';

import type {
  AssignedProgram,
  Group,
  Member,
  Trainer,
  SessionStatistic,
  UserStatistics,
} from '../../types';

const texts = locales.en.components.clients.userWorkoutsRow;

interface UserWorkoutsRowProps {
  groups: Group[];
  selected: boolean;
  onSelect: () => void;
  member: Member;
  trainer: Trainer | undefined;
  openEditClientModal: () => void;
  archiveMember: (member: Member) => Promise<void>;
  userType: UserType;
  onEditProgram: () => void;
  onCreateNewProgram: () => void;
  lastWorkoutSessionStatistic: SessionStatistic | null;
  last7DaysSessionStatistics: SessionStatistic[];
  last30DaysSessionStatistics: SessionStatistic[];
}

const UserWorkoutsRow: React.FC<UserWorkoutsRowProps> = ({
  groups,
  userType,
  member,
  trainer,
  onSelect,
  selected,
  archiveMember,
  openEditClientModal,
  onEditProgram,
  onCreateNewProgram,
  lastWorkoutSessionStatistic,
  last7DaysSessionStatistics,
  last30DaysSessionStatistics,
}) => {
  const refreshState = useRefreshState();
  const [isDeleteClientConfirmationOpen, setIsDeleteClientConfirmationOpen] =
    useState(false);
  const [isAssignProgramModalOpen, setIsAssignProgramModalOpen] =
    useState(false);
  const [isAddProgramTemplateModalOpen, setIsAddProgramTemplateModalOpen] =
    useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [userStatistics, setUserStatistics] = useState<UserStatistics | null>(
    null,
  );
  const [hovered, setHovered] = useState<string | null>(null);

  useEffect(() => {
    setUserStatistics(
      calculateUserStatistics(
        lastWorkoutSessionStatistic,
        last7DaysSessionStatistics,
        last30DaysSessionStatistics,
      ),
    );
  }, []);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const handleEditClient = () => {
    openEditClientModal();
    handleCloseMenuOptions();
  };

  const handleDeleteClient = async () => {
    handleCloseMenuOptions();
    await archiveMember(member);
    setIsDeleteClientConfirmationOpen(false);
  };

  const renderUserName = () => (
    <div className={classNames(styles.userColumn, styles.userName)}>
      <AccountCircleOutlinedIcon className={styles.userIcon} color="primary" />
      {getFullName(member)}
    </div>
  );

  const handleAssignProgramFromTemplate = async (program: AssignedProgram) => {
    await assignProgramToMembers([member.id], program);
    await refreshState();
    setIsAddProgramTemplateModalOpen(false);
  };

  const renderAssignProgramOptionsDialog = () => {
    if (!isAssignProgramModalOpen) return null;
    return (
      <AssignProgramOptionsDialog
        onCreate={() => {
          setIsAssignProgramModalOpen(false);
          onCreateNewProgram();
        }}
        onAdd={() => {
          setIsAssignProgramModalOpen(false);
          setIsAddProgramTemplateModalOpen(true);
        }}
        onClose={() => setIsAssignProgramModalOpen(false)}
      />
    );
  };

  const renderProgramTemplateDialog = () => (
    <AssignProgramDialog
      onSubmit={handleAssignProgramFromTemplate}
      isOpen={isAddProgramTemplateModalOpen}
      onClose={() => setIsAddProgramTemplateModalOpen(false)}
    />
  );

  const renderDeleteClientConfirmationDialog = () => (
    <ConfirmationDialog
      text={`Are you sure you want to delete this ${userType.toLowerCase()}?`}
      onSubmit={handleDeleteClient}
      submitText="Yes"
      cancelText="No"
      isOpen={isDeleteClientConfirmationOpen}
      onCancel={() => setIsDeleteClientConfirmationOpen(false)}
      symbol={<DeleteForeverOutlinedIcon />}
    />
  );

  const getUserOptions = () => {
    return [
      {
        onClick: handleEditClient,
        label: `Edit ${userType} Details`,
        icon: <BorderColorOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsDeleteClientConfirmationOpen(true),
        label: `Delete ${userType}`,
        icon: <DeleteForeverOutlinedIcon color="primary" />,
      },
      {
        onClick: () => setIsAssignProgramModalOpen(true),
        label: 'Assign Program',
        icon: <PersonAddAlt1OutlinedIcon color="primary" />,
      },
    ];
  };

  const getEnrichedUserOptions = () => {
    const options = getUserOptions();
    if (member.program) {
      options.push({
        onClick: () => {
          handleCloseMenuOptions();
          onEditProgram();
        },
        label: 'Edit Program',
        icon: <AppRegistrationIcon color="primary" />,
      });
    }
    return options;
  };

  const renderMoreOptions = () => (
    <div className={classNames(styles.userColumn, styles.options)}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => setAnchorEl(event.currentTarget)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleCloseMenuOptions}
        options={getEnrichedUserOptions()}
      />
    </div>
  );

  const getStatText = (stat: number | undefined | null) => {
    if (!stat) return '-';
    return `${stat}%`;
  };

  const renderComplianceRate = () => {
    const handleMouseEnter = (key: string) => {
      setHovered(key);
    };

    const handleMouseLeave = () => {
      setHovered(null);
    };

    return (
      <div className={styles.complianceRateContainer}>
        <div
          className={styles.rateTextContainer}
          onMouseEnter={() => handleMouseEnter('lastWorkout')}
          onMouseLeave={handleMouseLeave}
        >
          <span className={styles.rateText}>
            {`${getStatText(userStatistics?.lastWorkoutRepsCompletionPercentage)}`}
          </span>
          {hovered === 'lastWorkout' && (
            <div className={styles.tooltip}>LAST WORKOUT COMPLIANCE RATE</div>
          )}
        </div>
        <div
          className={styles.rateTextContainer}
          onMouseEnter={() => handleMouseEnter('lastWeek')}
          onMouseLeave={handleMouseLeave}
        >
          <span className={styles.rateText}>
            {`${getStatText(userStatistics?.lastWeekRepsCompletionPercentage)}`}
          </span>
          {hovered === 'lastWeek' && (
            <div className={styles.tooltip}>LAST WEEK COMPLIANCE RATE</div>
          )}
        </div>
        <div
          className={styles.rateTextContainer}
          onMouseEnter={() => handleMouseEnter('lastMonth')}
          onMouseLeave={handleMouseLeave}
        >
          <span className={styles.rateText}>
            {`${getStatText(userStatistics?.lastMonthRepsCompletionPercentage)}`}
          </span>
          {hovered === 'lastMonth' && (
            <div className={styles.tooltip}>LAST MONTH COMPLIANCE RATE</div>
          )}
        </div>
      </div>
    );
  };

  const renderVelocityPie = () => {
    const {
      lastWorkoutConcentricGoodRepsPercentage,
      lastWorkoutConcentricFastRepsPercentage,
      lastWorkoutConcentricSlowRepsPercentage,
      lastWeekConcentricGoodRepsPercentage,
      lastWeekConcentricFastRepsPercentage,
      lastWeekConcentricSlowRepsPercentage,
      lastMonthConcentricGoodRepsPercentage,
      lastMonthConcentricFastRepsPercentage,
      lastMonthConcentricSlowRepsPercentage,
    } = userStatistics || {};

    const renderLastWorkoutVelocityPie = () => {
      if (
        !lastWorkoutConcentricGoodRepsPercentage &&
        !lastWorkoutConcentricFastRepsPercentage &&
        !lastWorkoutConcentricSlowRepsPercentage
      ) {
        return (
          <div className={styles.velocityPieContainer}>
            <span className={styles.emptyStat}>-</span>
          </div>
        );
      }
      return (
        <div className={styles.velocityPieContainer}>
          <VelocityPie
            good={lastWorkoutConcentricGoodRepsPercentage}
            fast={lastWorkoutConcentricFastRepsPercentage}
            slow={lastWorkoutConcentricSlowRepsPercentage}
            subtitle={texts.lastWorkout}
          />
        </div>
      );
    };

    const renderLastWeekVelocityPie = () => {
      if (
        !lastWeekConcentricGoodRepsPercentage &&
        !lastWeekConcentricFastRepsPercentage &&
        !lastWeekConcentricSlowRepsPercentage
      ) {
        return (
          <div className={styles.velocityPieContainer}>
            <span className={styles.emptyStat}>-</span>
          </div>
        );
      }
      return (
        <div className={styles.velocityPieContainer}>
          <VelocityPie
            good={lastWeekConcentricGoodRepsPercentage}
            fast={lastWeekConcentricFastRepsPercentage}
            slow={lastWeekConcentricSlowRepsPercentage}
            subtitle={texts.last7Days}
          />
        </div>
      );
    };

    const renderLastMonthVelocityPie = () => {
      if (
        !lastMonthConcentricGoodRepsPercentage &&
        !lastMonthConcentricFastRepsPercentage &&
        !lastMonthConcentricSlowRepsPercentage
      ) {
        return (
          <div className={styles.velocityPieContainer}>
            <span className={styles.emptyStat}>-</span>
          </div>
        );
      }
      return (
        <div className={styles.velocityPieContainer}>
          <VelocityPie
            good={lastMonthConcentricGoodRepsPercentage}
            fast={lastMonthConcentricFastRepsPercentage}
            slow={lastMonthConcentricSlowRepsPercentage}
            subtitle={texts.last30Days}
          />
        </div>
      );
    };

    return (
      <div className={styles.velocityPiesContainer}>
        {renderLastWorkoutVelocityPie()}
        {renderLastWeekVelocityPie()}
        {renderLastMonthVelocityPie()}
      </div>
    );
  };

  return (
    <div
      onClick={onSelect}
      className={classNames(styles.userWorkoutsRow, styles.workoutsListGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderUserName()}
      {renderComplianceRate()}
      {renderVelocityPie()}
      {renderMoreOptions()}
      {renderDeleteClientConfirmationDialog()}
      {renderAssignProgramOptionsDialog()}
      {renderProgramTemplateDialog()}
    </div>
  );
};

export default UserWorkoutsRow;
