import React from 'react';
import styles from './RepInstance.module.scss';
import classNames from 'classnames';
import { VelocityRate } from '../../constants';

interface RepInstanceProps {
  repNumber: number;
  eccentricClass: VelocityRate;
  concentricClass: VelocityRate;
}

const RepInstance: React.FC<RepInstanceProps> = ({
  repNumber,
  eccentricClass,
  concentricClass,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.repNumber}>{`REP ${repNumber}`}</div>
      <div className={styles.boxes}>
        <div className={classNames(styles.box, styles[eccentricClass])}>
          <span className={styles.label}>EC</span>
        </div>
        <div className={classNames(styles.box, styles[concentricClass])}>
          <span className={styles.label}>CO</span>
        </div>
      </div>
    </div>
  );
};

export default RepInstance;
