export enum NavigationRoutes {
  LiveWorkouts = 'Live Workouts',
  Clients = 'Clients',
  Managers = 'Managers',
  Trainers = 'Trainers',
  Programs = 'Programs',
  Workouts = 'Workouts',
  Exercises = 'Exercises',
  Groups = 'Groups',
  GymSettings = 'Gym Settings',
  Gyms = 'Gyms',
}
