import type { AnalyzedMetrics } from '../../../types/automatic';

function randInRange(min: number, max: number): number {
  return parseFloat((Math.random() * (max - min) + min).toFixed(2));
}

function generateRep(
  memberId: string,
  performedSets: number,
  repNumber: number,
  base: Partial<AnalyzedMetrics>,
): AnalyzedMetrics {
  return {
    ...base,
    memberId,
    rep: repNumber,
    upDuration: randInRange(0.1, 1.5),
    downDuration: randInRange(0.1, 3),
    preset: { ...base.preset, performedSets },
  };
}

/**
 * Generates a sequence of incremental updates for one user performing a set of random length (between 8 and 10 reps).
 * Each update increments the data by one rep.
 * @param memberId User's memberId
 * @param performedSets The performedSets number
 * @param base Base partial metrics
 * @returns An array of updates, each is Record<string, AnalyzedMetrics[]>
 */
function generateUserSetUpdates(
  memberId: string,
  performedSets: number,
  base: Partial<AnalyzedMetrics>,
): Record<string, AnalyzedMetrics[]>[] {
  const updates: Record<string, AnalyzedMetrics[]>[] = [];
  let metrics: AnalyzedMetrics[] = [];

  // Random number of reps between 8 and 10
  const totalReps = Math.floor(Math.random() * 3) + 8; // 8,9 or 10

  for (let r = 1; r <= totalReps; r++) {
    metrics = [...metrics, generateRep(memberId, performedSets, r, base)];
    updates.push({ [memberId]: metrics });
  }
  return updates;
}

const baseRep: Partial<AnalyzedMetrics> = {
  rackNumber: 23,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 240,
    reps: 10, // Even though preset says 10, we will actually perform between 8 and 10 reps. The actual performed reps are what matter.
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

const baseRep2: Partial<AnalyzedMetrics> = {
  rackNumber: 23,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 120,
    reps: 10, // Even though preset says 10, we will actually perform between 8 and 10 reps. The actual performed reps are what matter.
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

const baseRep3: Partial<AnalyzedMetrics> = {
  rackNumber: 23,
  up: false,
  down: false,
  exerciseId: '5f39b4b5-a935-45cc-b394-aac15b128803',
  preset: {
    sets: 3,
    weight: 80,
    reps: 10, // Even though preset says 10, we will actually perform between 8 and 10 reps. The actual performed reps are what matter.
    minConcentric: 0.5,
    maxConcentric: 1,
    minEccentric: 1,
    maxEccentric: 2,
  },
};

// Sequence: user 8 set1 -> user 9 set1 -> user 10 set1 -> user 8 set2 -> user 9 set2 -> user 10 set2 -> user 8 set3 -> user 9 set3 -> user 10 set3
// Each time, reps count is random between 8 and 10.
const sequence = [
  ...generateUserSetUpdates('8', 1, baseRep),
  ...generateUserSetUpdates('9', 1, baseRep2),
  ...generateUserSetUpdates('10', 1, baseRep3),

  ...generateUserSetUpdates('8', 2, baseRep),
  ...generateUserSetUpdates('9', 2, baseRep2),
  ...generateUserSetUpdates('10', 2, baseRep3),

  ...generateUserSetUpdates('8', 3, baseRep),
  ...generateUserSetUpdates('9', 3, baseRep2),
  ...generateUserSetUpdates('10', 3, baseRep3),
];

export const rack_23_dynamic_data_mock = sequence;
