import type {
  Exercise,
  Gym,
  Member,
  Trainer,
  Muscle,
  Station,
  TrainingRoutineSession,
  TrainingRoutineTemplate,
  GymProduct,
  PerformedRoutine,
  PerformedSetGroup,
  Manager,
  Program,
  SessionStatistic,
} from '../types';

export const isStation = (obj: any): obj is Station => {
  return Boolean(obj.id && obj.exerciseIds);
};

export const isTemplate = (obj: any): obj is TrainingRoutineTemplate => {
  return Boolean(obj.id && obj.routine?.templateInfo?.trainerId);
};

export const isProgram = (obj: any): obj is Program => {
  return Boolean(obj.id && Array.isArray(obj.programItems));
};

export const isExercise = (obj: any): obj is Exercise => {
  return Boolean(obj.id && obj.name && obj.imageUrl);
};

export const isGym = (obj: any): obj is Gym => {
  return Boolean(obj.id && obj.name);
};

export const isMember = (obj: any): obj is Member => {
  return Boolean(obj.id && obj.pin);
};

export const isTrainer = (obj: any): obj is Trainer => {
  return Boolean(obj?.id && obj?.memberIds);
};

export const isManager = (obj: any): obj is Manager => {
  return Boolean(obj?.id && obj?.gymIds);
};

export const isGymProduct = (obj: any): obj is GymProduct => {
  return Boolean(obj.name);
};

export const isMuscle = (obj: any): obj is Muscle => {
  return Boolean(obj.id && obj.parentGroup);
};

export const isTrainingRoutineSession = (
  obj: any,
): obj is TrainingRoutineSession => {
  return Boolean(obj.id && obj.performedRoutine?.name);
};

export const isPerformedRoutine = (obj: any): obj is PerformedRoutine => {
  return Boolean(obj.performedExerciseGroups);
};

export const isPerformedSets = (obj: any): obj is PerformedSetGroup => {
  return Boolean(obj[0].status);
};

export const isSessionStatistic = (obj: any): obj is SessionStatistic => {
  return Boolean(obj.id);
};
