import React, { useState } from 'react';
import { TextField } from '@mui/material';
import classNames from 'classnames';
import styles from './RangeInputField.module.scss';

export interface RangeInputFieldProps {
  value: string; // "0.5 - 1.0" or "0.5" or empty string, etc.
  onChange: (value: string) => void;
  placeholder?: string; // e.g. "0.5 - 1.0"
  className?: string;
  error?: boolean;
}

/**
 * Parses a string like "0.5 - 1.0" into its numeric min/max.
 * If user enters only one number, or invalid input, we handle gracefully.
 */
function parseRange(rangeString: string) {
  let min: number | null = null;
  let max: number | null = null;

  const trimmedValue = rangeString.trim();
  if (!trimmedValue) {
    return { min, max };
  }

  // Check if there's a dash
  const dashIndex = trimmedValue.indexOf('-');
  if (dashIndex === -1) {
    // No dash -> treat as a single number for both
    const parsed = parseFloat(trimmedValue);
    if (!isNaN(parsed)) {
      min = parsed;
      max = parsed;
    }
  } else {
    // Split by dash
    const [minStr, maxStr] = trimmedValue.split('-').map((part) => part.trim());
    const parsedMin = parseFloat(minStr);
    const parsedMax = parseFloat(maxStr);
    if (!isNaN(parsedMin)) {
      min = parsedMin;
    }
    if (!isNaN(parsedMax)) {
      max = parsedMax;
    }
    // If user typed reversed range or only partial range, handle accordingly
    if (min !== null && max !== null && min > max) {
      // If reversed, just swap them
      [min, max] = [max, min];
    }
  }

  return { min, max };
}

/**
 * Format range so that if min === max, we display a single value;
 * else we display "min - max". If either is null, we return empty string.
 */
function formatRange(min: number | null, max: number | null) {
  if (min === null && max === null) {
    return '';
  }
  if (min !== null && max !== null) {
    return min === max ? `${min}` : `${min} - ${max}`;
  }
  // If only one side is valid, show that
  if (min !== null) return String(min);
  if (max !== null) return String(max);
  return '';
}

/**
 * A single text input that expects a "range" of values, e.g. "0.5 - 1.0".
 * The parent can parse or handle onChange as needed.
 */
const RangeInputField: React.FC<RangeInputFieldProps> = ({
  value,
  onChange,
  placeholder,
  className,
  error,
}) => {
  const [localValue, setLocalValue] = useState<string>(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newVal = e.target.value;
    setLocalValue(newVal);
    onChange(newVal);
  };

  return (
    <div className={classNames(styles.rangeInputFieldWrapper, className)}>
      <TextField
        variant="outlined"
        placeholder={placeholder || ''}
        value={localValue}
        error={error}
        onChange={handleChange}
        className={styles.rangeInputField}
        inputProps={{
          // You can tweak any styling or input constraints here
          style: { textAlign: 'center' },
        }}
      />
    </div>
  );
};

export { parseRange, formatRange };
export default RangeInputField;
