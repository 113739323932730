import type { AnalyzedMetrics } from '../../../types/automatic';

function randInRange(min: number, max: number): number {
  return Math.random() * (max - min) + min;
}

function createFrame(
  rackNumber: number,
  memberId: string,
  exerciseId: string,
  performedSets: number,
  rep: number,
): AnalyzedMetrics {
  return {
    rackNumber,
    rep,
    up: false,
    down: false,
    upDuration: randInRange(0.1, 1.5),
    downDuration: randInRange(0.1, 3),
    memberId,
    exerciseId,
    preset: {
      sets: 3,
      performedSets,
      weight: 40,
      reps: 10,
      minConcentric: 0.5,
      maxConcentric: 1,
      minEccentric: 1,
      maxEccentric: 2,
    },
  };
}

/**
 * generateMockData
 * @param rackNumber the rack station number
 * @param members array of memberIds for this rack
 * @param initialPerformedSets the initial performedSets for their first appearance
 * @param totalSets how many sets each member will perform
 * @param totalReps how many reps per set
 *
 * The logic will:
 * - Start with each member finishing their first set at rep=10.
 * - Then gradually introduce performedSets=2 and show increments.
 * - You can customize this logic as needed.
 */
export function generateMockData(
  rackNumber: number,
  members: string[],
  exerciseId: string = '5f39b4b5-a935-45cc-b394-aac15b128803',
  totalSets: number = 3,
  totalReps: number = 10,
): Record<string, AnalyzedMetrics[]>[] {
  const snapshots: Record<string, AnalyzedMetrics[]>[] = [];

  // Start scenario: each member finished rep=10 in performedSets=1
  const initialData: Record<string, AnalyzedMetrics[]> = {};
  for (const memberId of members) {
    // They already finished rep=10 in set=1
    const lastFrame = createFrame(rackNumber, memberId, exerciseId, 1, 10);
    // For realism, also add rep=9
    const penultimateFrame = { ...lastFrame, rep: 9 };
    initialData[memberId] = [penultimateFrame, lastFrame];
  }
  snapshots.push(initialData);

  // Now simulate starting their next sets and reps increment:
  // We'll just show each member performing performedSets=2, reps 1 to 10.
  for (const memberId of members) {
    const prevState = { ...snapshots[snapshots.length - 1] };
    // const prevMetrics = prevState[memberId] || [];
    // member starts performedSets=2 from rep=1 again
    const newSetStart = createFrame(rackNumber, memberId, exerciseId, 2, 1);
    prevState[memberId] = [newSetStart];
    snapshots.push(prevState);

    // Add reps 2 to 10 for performedSets=2
    for (let r = 2; r <= totalReps; r++) {
      const nextState = { ...snapshots[snapshots.length - 1] };
      const currMetrics = [...(nextState[memberId] || [])];
      currMetrics.push(createFrame(rackNumber, memberId, exerciseId, 2, r));
      nextState[memberId] = currMetrics;
      snapshots.push(nextState);
    }
  }

  return snapshots;
}
