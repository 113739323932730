import React, { useState } from 'react';
import classNames from 'classnames';
import { Button, CircularProgress, Box } from '@mui/material';
import styles from './GymSettings.module.scss';
import MultiSelectList from '../composites/MultiSelectList';
import { locales } from '../../constants';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import { useRefreshState, useLoginData } from '../../providers';
import { updateGymData } from '../../api';
import { Gym } from '../../types';

const texts = locales.en.components.gymSettings.gymSettings;

enum ContentTabs {
  Configuration = 'Configuration',
}

enum Features {
  athleteSwitch = 'Athlete Switch',
  automaticOffline = 'Automatic Offline',
  healthCheck = 'Device Health Check',
  signUp = 'Sign Up',
}

interface GymSettingsProps {
  gym?: Gym;
  onClose?: () => void;
}

const GymSettings: React.FC<GymSettingsProps> = ({ gym, onClose }) => {
  const { relatedGyms } = useLoginData();
  const foundGym = gym || relatedGyms[0];
  const refreshState = useRefreshState();
  const [selectedTab, setSelectedTab] = useState<ContentTabs>(
    ContentTabs.Configuration,
  );
  const [loading, setLoading] = useState(false);

  const gymFeatures = foundGym?.configuration?.enabledFeatures || {};
  const features = Object.entries(Features).map(([key, value]) => ({
    id: key,
    name: value,
    value: gymFeatures?.[key as keyof typeof Features] || false,
  }));

  const [selectedFeatures, setSelectedFeatures] = useState(
    Object.entries(gymFeatures || [])
      .filter(([key, value]) => value === true)
      .map(([key, value]) => ({
        id: key,
        name: Features[key as keyof typeof Features],
      })),
  );

  const renderContentTabs = () => (
    <Box className={styles.tabs}>
      {Object.values(ContentTabs).map((tab) => (
        <Button
          key={tab}
          variant="text"
          color="primary"
          onClick={() => setSelectedTab(tab)}
          className={classNames(styles.tab, {
            [styles.selected]: selectedTab === tab,
          })}
        >
          {tab}
        </Button>
      ))}
    </Box>
  );

  const renderContentHeader = () => {
    const getSubtitleText = () => {
      switch (selectedTab) {
        case ContentTabs.Configuration:
          return texts.configurationView.subtitle;
        default:
          return texts.configurationView.subtitle;
      }
    };

    return (
      <div className={styles.header}>
        <div className={styles.tabsContainer}>{renderContentTabs()}</div>
        <span className={styles.subtitle}>{getSubtitleText()}</span>
      </div>
    );
  };

  const renderConfiguration = () => {
    return (
      <div className={styles.configurationView}>
        <MultiSelectList
          listClassName={styles.list}
          items={features}
          selectedItems={selectedFeatures}
          setSelectedItems={setSelectedFeatures}
          EmptyStateComponent={() => null}
          ItemComponent={({ data }) => (
            <div className={styles.gymMultiSelectItem}>
              <div className={styles.configNameWrapper}>
                <div className={styles.configName}>{data.name}</div>
              </div>
            </div>
          )}
        />
      </div>
    );
  };

  const renderSelectedTabContent = () => {
    switch (selectedTab) {
      case ContentTabs.Configuration:
        return renderConfiguration();
      default:
        return renderConfiguration();
    }
  };

  const handleSubmit = async () => {
    if (!foundGym) return;
    setLoading(true);
    await updateGymData({
      ...foundGym,
      configuration: {
        ...foundGym.configuration,
        enabledFeatures: {
          ...selectedFeatures.reduce(
            (acc, { id }) => ({ ...acc, [id]: true }),
            {},
          ),
        },
      },
    });
    await refreshState();
    setLoading(false);
    onClose && onClose();
  };

  const renderSaveButton = () => (
    <Button
      variant="contained"
      color="primary"
      disabled={loading}
      onClick={handleSubmit}
    >
      {loading ? <CircularProgress size={24} color="primary" /> : 'Save'}
    </Button>
  );

  const renderContent = () => (
    <div className={styles.container}>
      {renderContentHeader()}
      {renderSelectedTabContent()}
      {renderSaveButton()}
    </div>
  );

  return onClose ? (
    renderContent()
  ) : (
    <DashboardContentWrapper
      drillInContent={null}
      title={texts.title}
      actions={[]}
    >
      {renderContent()}
    </DashboardContentWrapper>
  );
};

export default GymSettings;
