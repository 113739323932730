import {
  Exercise,
  PerformedSet,
  TrainingRoutineSession,
  PerformedSetGroup,
  SessionStatistic,
  UserStatistics,
} from '../types';
import { groupItemsByWeek } from './timerUtils';
import { getAllSets, isCompleteSet, isNumeric } from './utils';

export const getWeeklyCountWorkouts = (
  trainingRoutineSessions: TrainingRoutineSession[],
): number => {
  const currentDate = new Date();
  const currentDayOfWeek = currentDate.getDay(); // 0 is Sunday, 1 is Monday, ..., 6 is Saturday

  // Adjust current day to Monday if today is not Sunday
  const daysToMonday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 1;
  currentDate.setDate(currentDate.getDate() - daysToMonday);
  currentDate.setHours(0, 0, 0, 0);

  const lastMonday = currentDate.getTime();

  return trainingRoutineSessions.filter(
    ({ createdDate }) => new Date(createdDate).getTime() > lastMonday,
  ).length;
};

export const getMaxWorkoutsPerWeek = (
  trainingRoutineSessions: TrainingRoutineSession[],
): number => {
  const groupedWorkoutsPerWeek = groupItemsByWeek(trainingRoutineSessions);
  return Math.max(...groupedWorkoutsPerWeek.map((week) => week.length));
};

export const getAverageSetsCompletion = (
  trainingRoutineSessions: TrainingRoutineSession[],
): number => {
  return Math.round(
    trainingRoutineSessions.reduce(
      (acc, session) => acc + getTrainingSessionSetsCompletion(session),
      0,
    ) / trainingRoutineSessions.length,
  );
};

const getPerformedSetVolume = (
  exercises: Record<string, Exercise>,
  { exerciseId, reps, performedReps, weight, performedWeight }: PerformedSet,
  usePerformedVolume: boolean,
): number => {
  const unilateralMultiplyer = exercises[exerciseId]?.isUnilateral ? 2 : 1;
  const weightsMultiplyer = exercises[exerciseId]?.isWeightsPerSide ? 2 : 1;
  if (usePerformedVolume) {
    if (performedReps && performedWeight) {
      const singleVolume = performedReps * performedWeight;
      return singleVolume * unilateralMultiplyer * weightsMultiplyer;
    }
  } else if (reps && weight) {
    const singleVolume = reps * weight;
    return singleVolume * unilateralMultiplyer * weightsMultiplyer;
  }
  return 0;
};

const calculatePerformedSetsVolume = (
  exercises: Record<string, Exercise>,
  performedSets: PerformedSetGroup,
  usePerformedVolume: boolean,
): number => {
  return performedSets.reduce(
    (acc, set) =>
      acc + getPerformedSetVolume(exercises, set, usePerformedVolume),
    0,
  );
};

export const getTrainingSessionPerformedVolume = (
  exercises: Record<string, Exercise>,
  { performedRoutine }: TrainingRoutineSession,
): number => {
  const completedSets = getAllSets(
    performedRoutine.performedExerciseGroups,
  ).filter(isCompleteSet);
  return calculatePerformedSetsVolume(exercises, completedSets, true);
};

export const getTrainingSessionTotalVolume = (
  exercises: Record<string, Exercise>,
  { performedRoutine }: TrainingRoutineSession,
): number => {
  const allSets = getAllSets(performedRoutine.performedExerciseGroups);
  return calculatePerformedSetsVolume(exercises, allSets, false);
};

export const getTrainingSessionVolumeCompletion = (
  exercises: Record<string, Exercise>,
  trainingRoutineSession: TrainingRoutineSession,
): number => {
  return Math.round(
    (getTrainingSessionPerformedVolume(exercises, trainingRoutineSession) /
      getTrainingSessionTotalVolume(exercises, trainingRoutineSession)) *
      100,
  );
};

export const getAverageVolumeCompletion = (
  exercises: Record<string, Exercise>,
  trainingRoutineSession: TrainingRoutineSession[],
): number => {
  return Math.round(
    trainingRoutineSession.reduce(
      (acc, session) =>
        acc + getTrainingSessionVolumeCompletion(exercises, session),
      0,
    ) / trainingRoutineSession.length,
  );
};

export const getTrainingSessionSetsCompletion = ({
  performedRoutine,
}: TrainingRoutineSession): number => {
  const allSets = getAllSets(performedRoutine.performedExerciseGroups);
  const completedSets = allSets.filter(isCompleteSet);
  return Math.ceil((completedSets.length / allSets.length) * 100);
};

export const adjustPercentageToText = (percentage: number): string => {
  if (percentage > 0) {
    return `${percentage}`;
  }
  return '0';
};

export const getWorkoutStreaks = (
  trainingRoutineSession: TrainingRoutineSession[],
): { lastStreak: number; highestStreak: number } => {
  if (trainingRoutineSession.length === 0) {
    return {
      lastStreak: 0,
      highestStreak: 0,
    };
  }

  const trainingsGroupedByWeeks = groupItemsByWeek(trainingRoutineSession);
  let lastStreak = 0;
  let currentStreak = 0;
  let highestStreak = 0;

  trainingsGroupedByWeeks.forEach((trainingsInWeek) => {
    if (trainingsInWeek.length > 0) {
      // Count the current streak
      currentStreak++;
      // Update the highest streak if needed
      highestStreak = Math.max(highestStreak, currentStreak);
    } else {
      // Reset the current streak if the array is empty
      currentStreak = 0;
    }

    // Update the last streak for each row
    lastStreak = trainingsInWeek.length > 0 ? currentStreak : 0;
  });

  return { lastStreak, highestStreak };
};

export const getExerciseWeightsHistory = (
  exercise: Exercise,
  trainingRoutineSessions: TrainingRoutineSession[],
): number[] => {
  return trainingRoutineSessions
    .reduce((exerciseWeightsHistory, { performedRoutine }) => {
      const completedSets = getAllSets(
        performedRoutine.performedExerciseGroups,
      ).filter(isCompleteSet);
      completedSets.forEach(({ exerciseId, performedWeight }) => {
        if (exerciseId === exercise.id && isNumeric(performedWeight)) {
          exerciseWeightsHistory.push(performedWeight);
        }
      });
      return exerciseWeightsHistory;
    }, [] as number[])
    .slice(-10);
};

// How much exercises passed the history performedDuration
export const getPersonalRecords = (
  currentSession: TrainingRoutineSession,
  historySessions: TrainingRoutineSession[],
): number => {
  let personalRecords = 0;
  const currentMaxWeights = new Map<string, number>();
  currentSession.performedRoutine.performedExerciseGroups.forEach((group) => {
    group.sets.forEach((set) => {
      set.forEach((performedSet) => {
        if (performedSet.performedWeight !== null) {
          const currentMax =
            currentMaxWeights.get(performedSet.exerciseId) || 0;
          if (performedSet.performedWeight > currentMax) {
            currentMaxWeights.set(
              performedSet.exerciseId,
              performedSet.performedWeight,
            );
          }
        }
      });
    });
  });
  currentMaxWeights.forEach((weight, exerciseId) => {
    let isPersonalRecord = true;
    historySessions.forEach((session) => {
      session.performedRoutine.performedExerciseGroups.forEach((group) => {
        group.sets.forEach((set) => {
          set.forEach((performedSet) => {
            if (
              performedSet.exerciseId === exerciseId &&
              performedSet.performedWeight !== null
            ) {
              if (performedSet.performedWeight >= weight) {
                isPersonalRecord = false;
              }
            }
          });
        });
      });
    });
    if (isPersonalRecord) {
      personalRecords++;
    }
  });
  return personalRecords;
};

export const calculateUserStatistics = (
  lastWorkoutSessionStatistics: SessionStatistic | null,
  last7DaysSessionStatistics: SessionStatistic[],
  last30DaysSessionStatistics: SessionStatistic[],
): UserStatistics => {
  if (
    !lastWorkoutSessionStatistics ||
    !last7DaysSessionStatistics ||
    !last30DaysSessionStatistics
  ) {
    return {
      lastWorkoutRepsCompletionPercentage: 0,
      lastWeekRepsCompletionPercentage: 0,
      lastMonthRepsCompletionPercentage: 0,
      lastWorkoutConcentricGoodRepsPercentage: 0,
      lastWorkoutConcentricSlowRepsPercentage: 0,
      lastWorkoutConcentricFastRepsPercentage: 0,
      lastWeekConcentricGoodRepsPercentage: 0,
      lastWeekConcentricSlowRepsPercentage: 0,
      lastWeekConcentricFastRepsPercentage: 0,
      lastMonthConcentricGoodRepsPercentage: 0,
      lastMonthConcentricSlowRepsPercentage: 0,
      lastMonthConcentricFastRepsPercentage: 0,
    };
  }
  // TODO: Replace this with the actual dates checking
  const lastWorkoutSessionStatistic = lastWorkoutSessionStatistics;
  const lastWeekSessionStatistics = last7DaysSessionStatistics;
  const lastMonthSessionStatistics = last30DaysSessionStatistics;

  const lastWorkoutRepsCompletionPercentage = Math.round(
    (lastWorkoutSessionStatistic.totalPerformedReps /
      lastWorkoutSessionStatistic.totalReps) *
      100,
  );
  const lastWeekRepsCompletionPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.totalPerformedReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.totalReps,
        0,
      )) *
      100,
  );
  const lastMonthRepsCompletionPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.totalPerformedReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.totalReps,
        0,
      )) *
      100,
  );
  const aiPerformedReps = lastWorkoutSessionStatistic.aiTotalPerformedReps;
  const lastWorkoutConcentricGoodRepsPercentage = aiPerformedReps
    ? Math.round((lastWorkoutSessionStatistic.goodReps / aiPerformedReps) * 100)
    : 0;
  const lastWorkoutConcentricSlowRepsPercentage = aiPerformedReps
    ? Math.round((lastWorkoutSessionStatistic.slowReps / aiPerformedReps) * 100)
    : 0;
  const lastWorkoutConcentricFastRepsPercentage = aiPerformedReps
    ? Math.round((lastWorkoutSessionStatistic.fastReps / aiPerformedReps) * 100)
    : 0;
  const lastWeekConcentricGoodRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.goodReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastWeekConcentricSlowRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.slowReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastWeekConcentricFastRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.fastReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricGoodRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.goodReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricSlowRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.slowReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricFastRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.fastReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );

  return {
    lastWorkoutRepsCompletionPercentage,
    lastWeekRepsCompletionPercentage,
    lastMonthRepsCompletionPercentage,
    lastWorkoutConcentricGoodRepsPercentage,
    lastWorkoutConcentricSlowRepsPercentage,
    lastWorkoutConcentricFastRepsPercentage,
    lastWeekConcentricGoodRepsPercentage,
    lastWeekConcentricSlowRepsPercentage,
    lastWeekConcentricFastRepsPercentage,
    lastMonthConcentricGoodRepsPercentage,
    lastMonthConcentricSlowRepsPercentage,
    lastMonthConcentricFastRepsPercentage,
  };
};

export const calculateOverallStatistics = (
  lastWorkoutSessionStatistics: SessionStatistic[],
  last7DaysSessionStatistics: SessionStatistic[],
  last30DaysSessionStatistics: SessionStatistic[],
): UserStatistics => {
  if (
    !lastWorkoutSessionStatistics ||
    !last7DaysSessionStatistics ||
    !last30DaysSessionStatistics
  ) {
    return {
      lastWorkoutRepsCompletionPercentage: 0,
      lastWeekRepsCompletionPercentage: 0,
      lastMonthRepsCompletionPercentage: 0,
      lastWorkoutConcentricGoodRepsPercentage: 0,
      lastWorkoutConcentricSlowRepsPercentage: 0,
      lastWorkoutConcentricFastRepsPercentage: 0,
      lastWeekConcentricGoodRepsPercentage: 0,
      lastWeekConcentricSlowRepsPercentage: 0,
      lastWeekConcentricFastRepsPercentage: 0,
      lastMonthConcentricGoodRepsPercentage: 0,
      lastMonthConcentricSlowRepsPercentage: 0,
      lastMonthConcentricFastRepsPercentage: 0,
    };
  }
  const lastWeekSessionStatistics = last7DaysSessionStatistics;
  const lastMonthSessionStatistics = last30DaysSessionStatistics;

  const lastWorkoutRepsCompletionPercentage = Math.round(
    (lastWorkoutSessionStatistics.reduce(
      (acc, session) => acc + session.totalPerformedReps,
      0,
    ) /
      lastWorkoutSessionStatistics.reduce(
        (acc, session) => acc + session.totalReps,
        0,
      )) *
      100,
  );
  const lastWeekRepsCompletionPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.totalPerformedReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.totalReps,
        0,
      )) *
      100,
  );
  const lastMonthRepsCompletionPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.totalPerformedReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.totalReps,
        0,
      )) *
      100,
  );
  const aiPerformedReps = lastWorkoutSessionStatistics.reduce(
    (acc, session) => acc + session.aiTotalPerformedReps,
    0,
  );
  const lastWorkoutConcentricGoodRepsPercentage = aiPerformedReps
    ? Math.round(
        (lastWorkoutSessionStatistics.reduce(
          (acc, session) => acc + session.goodReps,
          0,
        ) /
          aiPerformedReps) *
          100,
      )
    : 0;
  const lastWorkoutConcentricSlowRepsPercentage = aiPerformedReps
    ? Math.round(
        (lastWorkoutSessionStatistics.reduce(
          (acc, session) => acc + session.slowReps,
          0,
        ) /
          aiPerformedReps) *
          100,
      )
    : 0;
  const lastWorkoutConcentricFastRepsPercentage = aiPerformedReps
    ? Math.round(
        (lastWorkoutSessionStatistics.reduce(
          (acc, session) => acc + session.fastReps,
          0,
        ) /
          aiPerformedReps) *
          100,
      )
    : 0;
  const lastWeekConcentricGoodRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.goodReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastWeekConcentricSlowRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.slowReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastWeekConcentricFastRepsPercentage = Math.round(
    (lastWeekSessionStatistics.reduce(
      (acc, session) => acc + session.fastReps,
      0,
    ) /
      lastWeekSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricGoodRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.goodReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricSlowRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.slowReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );
  const lastMonthConcentricFastRepsPercentage = Math.round(
    (lastMonthSessionStatistics.reduce(
      (acc, session) => acc + session.fastReps,
      0,
    ) /
      lastMonthSessionStatistics.reduce(
        (acc, session) => acc + session.aiTotalPerformedReps,
        0,
      )) *
      100,
  );

  return {
    lastWorkoutRepsCompletionPercentage,
    lastWeekRepsCompletionPercentage,
    lastMonthRepsCompletionPercentage,
    lastWorkoutConcentricGoodRepsPercentage,
    lastWorkoutConcentricSlowRepsPercentage,
    lastWorkoutConcentricFastRepsPercentage,
    lastWeekConcentricGoodRepsPercentage,
    lastWeekConcentricSlowRepsPercentage,
    lastWeekConcentricFastRepsPercentage,
    lastMonthConcentricGoodRepsPercentage,
    lastMonthConcentricSlowRepsPercentage,
    lastMonthConcentricFastRepsPercentage,
  };
};

export const getLastWorkoutSessionStatistics = (
  sessionStatistics: SessionStatistic[],
): SessionStatistic | null => {
  if (!sessionStatistics || sessionStatistics.length === 0) {
    return null;
  }
  return sessionStatistics.reduce((lastSession, session) => {
    if (new Date(session.createdDate) > new Date(lastSession.createdDate)) {
      return session;
    }
    return lastSession;
  });
};
