import { generateMockData } from './util'; // Adjust import as needed

export const rack_6_dynamic_data_mock = generateMockData(
  1,
  ['17', '18', '19'],
  '5f39b4b5-a935-45cc-b394-aac15b128803',
  3,
  10,
);

export const rack_7_dynamic_data_mock = generateMockData(
  1,
  ['20', '21', '22'],
  '5f39b4b5-a935-45cc-b394-aac15b128803',
  3,
  10,
);

// ... and so on up to rack_20:
export const rack_8_dynamic_data_mock = generateMockData(1, ['23', '24', '25']);
export const rack_9_dynamic_data_mock = generateMockData(1, ['26', '27', '28']);
export const rack_10_dynamic_data_mock = generateMockData(1, [
  '29',
  '30',
  '31',
]);
export const rack_11_dynamic_data_mock = generateMockData(1, [
  '32',
  '33',
  '34',
]);
export const rack_12_dynamic_data_mock = generateMockData(1, [
  '35',
  '36',
  '37',
]);
export const rack_13_dynamic_data_mock = generateMockData(1, [
  '38',
  '39',
  '40',
]);
export const rack_14_dynamic_data_mock = generateMockData(1, [
  '41',
  '42',
  '43',
]);
export const rack_15_dynamic_data_mock = generateMockData(1, [
  '44',
  '45',
  '46',
]);
export const rack_16_dynamic_data_mock = generateMockData(1, [
  '47',
  '48',
  '49',
]);
export const rack_17_dynamic_data_mock = generateMockData(1, [
  '50',
  '51',
  '52',
]);
export const rack_18_dynamic_data_mock = generateMockData(1, [
  '53',
  '54',
  '55',
]);
export const rack_19_dynamic_data_mock = generateMockData(1, [
  '56',
  '57',
  '58',
]);
export const rack_20_dynamic_data_mock = generateMockData(1, [
  '59',
  '60',
  '61',
]);
