import React from 'react';
import styles from './VelocityPie.module.scss';

interface VelocityPieProps {
  good: number | undefined;
  fast: number | undefined;
  slow: number | undefined;
  subtitle?: string;
}

const VelocityPie: React.FC<VelocityPieProps> = ({
  good,
  fast,
  slow,
  subtitle,
}) => {
  // Calculate percentages and handle the 'other' case
  if (good === undefined || fast === undefined || slow === undefined) {
    return null;
  }
  const total = good + fast + slow;
  const other = total < 100 ? 100 - total : 0;

  const maxPercentage = Math.max(good, fast, slow, other);
  const borderColor =
    maxPercentage === good
      ? 'green'
      : maxPercentage === fast
        ? 'yellow'
        : maxPercentage === slow
          ? 'red'
          : 'gray';

  return (
    <div className={styles.velocityPie} style={{ borderColor }}>
      <div className={styles.labelsContainer}>
        <span className={styles.label} style={{ width: `${good}%` }}>
          {good > 0 && `${good}%`}
        </span>
        <span className={styles.label} style={{ width: `${fast}%` }}>
          {fast > 0 && `${fast}%`}
        </span>
        <span className={styles.label} style={{ width: `${slow}%` }}>
          {slow > 0 && `${slow}%`}
        </span>
        {other > 1 && (
          <span className={styles.label} style={{ width: `${other}%` }}>
            {other > 0 && `${other}%`}
          </span>
        )}
      </div>
      <div className={styles.barContainer}>
        <div
          className={styles.section}
          style={{ backgroundColor: 'green', flex: good }}
        ></div>
        <div
          className={styles.section}
          style={{ backgroundColor: 'yellow', flex: fast }}
        ></div>
        <div
          className={styles.section}
          style={{ backgroundColor: 'red', flex: slow }}
        ></div>
        {other > 1 && (
          <div
            className={styles.section}
            style={{ backgroundColor: 'gray', flex: other }}
          ></div>
        )}
      </div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default VelocityPie;
