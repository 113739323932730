export const locales = {
  en: {
    components: {
      workouts: {
        editTemplate: {
          titlePlaceholder: 'Input workout name',
          descriptionPlaceholder: 'Add Description Here...',
        },
        exerciseGroup: {
          superset: 'Superset',
          circuit: 'Circuit',
          exerciseGroupReorderDropZone: 'DROP HERE TO CHANGE ORDER OF EXERCISE',
          exerciseGroup: 'Exercise Group',
        },
        groupSet: {
          groupSetReorderDropZone: 'DROP HERE TO ADD EXERCISE TO SUPERSET',
        },
      },
      programs: {
        editProgram: {
          titlePlaceholder: 'Input program name',
        },
      },
      clients: {
        clientsContent: {
          summary: 'Summary',
          Workouts: 'Workouts',
          PER_ATHELETE_METRICS: 'PER ATHELETE METRICS',
          OVERALL_METRICS: 'OVERALL METRICS',
          lastWorkout: 'LAST WORKOUT',
          last7Days: 'LAST 7 DAYS',
          last30Days: 'LAST 30 DAYS',
        },
        userWorkoutsRow: {
          lastWorkout: 'LAST WORKOUT',
          last7Days: 'LAST 7 DAYS',
          last30Days: 'LAST 30 DAYS',
        },
      },
      liveWorkouts: {
        liveWorkouts: {
          title: 'Live Workouts',
          rackView: {
            subtitle: 'Here is a breakdown of all Rack stations live',
          },
          athleteView: {
            subtitle: 'Here is a breakdown of athlete’s live workout progress',
          },
          groupView: {
            subtitle: 'Here is a breakdown of groups live workout progress',
          },
        },
        liveRack: {
          rack: 'RACK',
        },
      },
      gymSettings: {
        gymSettings: {
          title: 'Gym Settings',
          configurationView: {
            subtitle: 'Enabled Features',
          },
        },
      },
    },
  },
};
