import { getDataWithRetry } from '../fetchUtils';
import { apiBaseUrl } from '../apiConsts';

import type { SessionStatistic } from '../../types';

export const fetchSessionStatistics = async (
  memberIds: string[],
  from: string,
  to: string,
): Promise<Record<string, SessionStatistic[]>> => {
  if (!memberIds?.length) {
    throw new Error('memberIds is required');
  }
  try {
    const data = getDataWithRetry(
      `${apiBaseUrl}session-statistics/range`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify({ memberIds, from, to }),
      },
      () => true,
    );
    return data;
  } catch (e) {
    console.error('Error in fetchSessionStatistics:', e);
    return {};
  }
};

export const fetchLastSessionStatistics = async (
  memberIds: string[],
): Promise<Record<string, SessionStatistic>> => {
  if (!memberIds?.length) {
    throw new Error('memberId is required');
  }
  try {
    const data = getDataWithRetry(
      `${apiBaseUrl}session-statistics/last/getByIds`,
      {
        headers: { 'content-type': 'application/json' },
        method: 'POST',
        body: JSON.stringify([...memberIds]),
      },
      () => true,
    );
    return data;
  } catch (e) {
    console.error('Error in fetchLastSessionStatistics:', e);
    return {};
  }
};
