import React, { useState, useEffect, useMemo, useRef } from 'react';
import styles from './LiveRack.module.scss';
import classNames from 'classnames';
import { locales, VelocityRate } from '../../constants';
import { getInitialsFromFirstAndLastName } from '../../utils';
import { AnalyzedMetrics } from '../../types/automatic';

//TODO: Chnge with real members
import { members } from '../../mock/liveWorkouts';

interface LiveRackProps {
  number: number;
  data: Record<string, AnalyzedMetrics[]>;
}

const texts = locales.en.components.liveWorkouts.liveRack;

function classifyRep(upDuration: number, minC: number, maxC: number) {
  if (upDuration < minC) return 'fast';
  if (upDuration > maxC) return 'slow';
  return 'good';
}

const LiveRack: React.FC<LiveRackProps> = ({ number, data }) => {
  const [overallVelocityRate, setOverallVelocityRate] = useState<VelocityRate>(
    VelocityRate.Good,
  );
  const [activeUserId, setActiveUserId] = useState<string | null>(null);

  const prevDataRef = useRef<Record<string, AnalyzedMetrics[]>>({});

  const userIds = useMemo(() => Object.keys(data), [data]);

  // Determine which user just got a new data frame (if any)
  useEffect(() => {
    const prevData = prevDataRef.current;
    let updatedUser: string | null = null;

    userIds.forEach((userId) => {
      const prevLen = prevData[userId]?.length || 0;
      const newLen = data[userId]?.length || 0;
      if (newLen !== prevLen) {
        updatedUser = userId;
      }
    });

    if (updatedUser) {
      setActiveUserId(updatedUser);
    }

    prevDataRef.current = data;
  }, [data, userIds]);

  const userClassifications = useMemo(() => {
    return userIds.map((userId) => {
      const metrics = data[userId] || [];
      if (metrics.length === 0) {
        return {
          userId,
          isActive: userId === activeUserId,
          color: 'green',
          goodCount: 0,
          fastCount: 0,
          slowCount: 0,
          totalCount: 0,
        };
      }

      const lastFrame = metrics[metrics.length - 1];
      const { minConcentric, maxConcentric } = lastFrame.preset || {};

      let goodCount = 0;
      let fastCount = 0;
      let slowCount = 0;

      if (minConcentric == null || maxConcentric == null) {
        // No preset info, consider all as good reps
        goodCount = metrics.length;
      } else {
        for (const frame of metrics) {
          if (frame.upDuration == null) continue;
          const repType = classifyRep(
            frame.upDuration,
            minConcentric,
            maxConcentric,
          );
          if (repType === 'good') goodCount++;
          if (repType === 'fast') fastCount++;
          if (repType === 'slow') slowCount++;
        }
      }

      const totalCount = goodCount + fastCount + slowCount;
      let color = 'green';
      if (totalCount > 0) {
        const goodPct = goodCount / totalCount;
        const fastPct = fastCount / totalCount;
        const slowPct = slowCount / totalCount;
        if (goodPct > fastPct && goodPct > slowPct) {
          color = 'green';
        } else if (fastPct > goodPct && fastPct > slowPct) {
          color = 'yellow';
        } else if (slowPct > goodPct && slowPct > fastPct) {
          color = 'red';
        }
      }

      return {
        userId,
        isActive: userId === activeUserId,
        color,
        goodCount,
        fastCount,
        slowCount,
        totalCount,
      };
    });
  }, [data, userIds, activeUserId]);

  useEffect(() => {
    let totalGood = 0;
    let totalFast = 0;
    let totalSlow = 0;

    for (const u of userClassifications) {
      totalGood += u.goodCount;
      totalFast += u.fastCount;
      totalSlow += u.slowCount;
    }

    const totalReps = totalGood + totalFast + totalSlow;
    let overall = VelocityRate.Good;

    if (totalReps > 0) {
      const goodPct = totalGood / totalReps;
      const fastPct = totalFast / totalReps;
      const slowPct = totalSlow / totalReps;
      if (goodPct > fastPct && goodPct > slowPct) overall = VelocityRate.Good;
      else if (fastPct > goodPct && fastPct > slowPct)
        overall = VelocityRate.Fast;
      else if (slowPct > goodPct && slowPct > fastPct)
        overall = VelocityRate.Slow;
    }

    setOverallVelocityRate(overall);
  }, [userClassifications]);

  const getMemberInitials = (userId: string) => {
    const member = members.find((m) => m.id === userId);
    if (!member) return '';
    return getInitialsFromFirstAndLastName(member.firstName, member.lastName);
  };

  return (
    <div
      className={classNames(styles.container, {
        [styles.goodRate]: overallVelocityRate === VelocityRate.Good,
        [styles.fastRate]: overallVelocityRate === VelocityRate.Fast,
        [styles.slowRate]: overallVelocityRate === VelocityRate.Slow,
      })}
    >
      <span className={styles.title}>{`${texts.rack} ${number}`}</span>
      <div className={styles.usersContainer}>
        {userClassifications.map((u) => (
          <div
            key={u.userId}
            className={styles.userWrapper}
            style={{ width: `${100 / userIds.length}%` }}
          >
            <div
              className={classNames(styles.userBox, {
                [styles.active]: u.isActive,
                [styles.goodRate]: u.color === 'green',
                [styles.fastRate]: u.color === 'yellow',
                [styles.slowRate]: u.color === 'red',
              })}
              title={u.userId}
            />
            <span
              className={classNames(styles.userInitials, {
                [styles.active]: u.isActive,
              })}
            >
              {getMemberInitials(u.userId)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveRack;
