import React from 'react';
import styles from './InputField.module.scss';
import { TextField, MenuItem } from '@mui/material';
import classNames from 'classnames';

interface DropDownFieldProps<
  T extends string | number | readonly string[] | undefined,
> {
  title?: string;
  inputName: string;
  placeholder?: string;
  value: T;
  options?: Array<{ value: T; label: string }>;
  icon?: React.ReactNode;
  onChange: (value: string) => void;
  error?: boolean;
  className?: string;
  autoComplete?: string;
  onBlur?: () => void;
  type?: 'number' | 'time' | 'dropdown';
}

const formatTimeValue = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
};

const formatDropDownValue = (value: any) => {
  if (value === 'NaN') {
    return '';
  }
  return value;
};

const parseTimeValue = (timeString: string) => {
  if (timeString.includes(':')) {
    const [minutes, seconds] = timeString.split(':').map(Number);
    return minutes * 60 + seconds;
  }
  return Number(timeString);
};

const sanitizeNumericValue = (input: string) => {
  if (input.length > 1 && input.startsWith('0')) {
    return input.replace(/^0+/, '');
  }
  return input;
};

const InputField = <T extends string | number | readonly string[] | undefined>({
  options,
  value,
  icon,
  type,
  onChange,
  inputName,
  autoComplete,
  title,
  placeholder,
  onBlur,
  error,
  className,
}: DropDownFieldProps<T>) => {
  const isSelect = !!options?.length;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    if (type === 'time') {
      onChange(String(parseTimeValue(newValue)));
    } else {
      console.log('new value', newValue);
      onChange(sanitizeNumericValue(newValue));
    }
  };

  const displayedValue =
    type === 'time'
      ? formatTimeValue(Number(value))
      : type === 'dropdown'
        ? formatDropDownValue(value)
        : value;

  console.log('value', value);

  return (
    <div className={classNames(styles.inputFieldItem, className)}>
      {(icon || title) && (
        <div className={styles.fieldTitleWrapper}>
          {icon}
          {title && <span className={styles.fieldTitle}>{title}</span>}
        </div>
      )}
      <div className={styles.inputWrapper}>
        <TextField
          name={inputName}
          margin="dense"
          select={isSelect}
          fullWidth
          type={type === 'time' ? 'text' : type}
          autoComplete={autoComplete}
          variant="outlined"
          value={displayedValue}
          onChange={handleChange}
          error={error}
          className={classNames(styles.inputField, {
            [styles.inputFieldDropDown]: isSelect,
            [styles.inputFieldError]: error,
          })}
          onBlur={onBlur}
        >
          {options?.length &&
            options.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
        </TextField>
        <span
          className={classNames(styles.inputFieldPlaceholder, {
            [styles.inputFieldPlaceholderHidden]: !!value,
            [styles.dropDownInputPlaceholder]:
              type === 'dropdown' && value === 'NaN',
          })}
        >
          {placeholder}
        </span>
      </div>
    </div>
  );
};

export default InputField;
