import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button, Box } from '@mui/material';
import styles from './LiveWorkouts.module.scss';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { locales } from '../../constants';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import LiveRack from './LiveRack';
import LiveAthleteView from './LiveAthleteView';

// Import all mock data for racks 1 through 20
import {
  rack_1_dynamic_data_mock,
  rack_2_dynamic_data_mock,
  rack_3_dynamic_data_mock,
  rack_4_dynamic_data_mock,
  rack_5_dynamic_data_mock,
  rack_6_dynamic_data_mock,
  rack_7_dynamic_data_mock,
  rack_8_dynamic_data_mock,
  rack_9_dynamic_data_mock,
  rack_10_dynamic_data_mock,
  rack_11_dynamic_data_mock,
  rack_12_dynamic_data_mock,
  rack_13_dynamic_data_mock,
  rack_14_dynamic_data_mock,
  rack_15_dynamic_data_mock,
  rack_16_dynamic_data_mock,
  rack_17_dynamic_data_mock,
  rack_18_dynamic_data_mock,
  rack_19_dynamic_data_mock,
  rack_20_dynamic_data_mock,
  // The AthleteView racks
  rack_22_dynamic_data_mock,
  rack_23_dynamic_data_mock,
} from '../../mock/liveWorkouts';

const texts = locales.en.components.liveWorkouts.liveWorkouts;

enum ContentTabs {
  RackView = 'Rack View',
  AthleteView = 'Athlete View',
  GroupView = 'Group View',
}

// Combine all datasets for RackView into an array so we can iterate over them
// Now we include racks 1 through 20
const racksDataSets = [
  rack_1_dynamic_data_mock,
  rack_2_dynamic_data_mock,
  rack_3_dynamic_data_mock,
  rack_4_dynamic_data_mock,
  rack_5_dynamic_data_mock,
  rack_6_dynamic_data_mock,
  rack_7_dynamic_data_mock,
  rack_8_dynamic_data_mock,
  rack_9_dynamic_data_mock,
  rack_10_dynamic_data_mock,
  rack_11_dynamic_data_mock,
  rack_12_dynamic_data_mock,
  rack_13_dynamic_data_mock,
  rack_14_dynamic_data_mock,
  rack_15_dynamic_data_mock,
  rack_16_dynamic_data_mock,
  rack_17_dynamic_data_mock,
  rack_18_dynamic_data_mock,
  rack_19_dynamic_data_mock,
  rack_20_dynamic_data_mock,
];

// Mock data for the athlete view
const racksDataSetsForAthleteView = [
  rack_22_dynamic_data_mock,
  rack_23_dynamic_data_mock,
];

const LiveWorkouts: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<ContentTabs>(
    ContentTabs.RackView,
  );

  // Initialize each rack’s current index and data for RackView
  const [racksCurrentDataIndex, setRacksCurrentDataIndex] = useState<number[]>(
    racksDataSets.map(() => 0), // start all racks at index 0
  );

  const [racksCurrentData, setRacksCurrentData] = useState<
    Record<string, any>[]
  >(
    racksDataSets.map((ds) => ds[0]), // initial data from index 0 for each rack
  );

  // Initialize each rack’s current index and data for AthleteView
  const [
    racksDataSetsForAthleteViewIndex,
    setRacksDataSetsForAthleteViewIndex,
  ] = useState<number[]>(
    racksDataSetsForAthleteView.map(() => 0), // start all at index 0
  );

  const [racksDataSetsForAthleteViewData, setRacksDataSetsForAthleteViewData] =
    useState<Record<string, any>[]>(
      racksDataSetsForAthleteView.map((ds) => ds[0]),
    );

  useEffect(() => {
    // Update each rack's data index every second
    const interval = setInterval(() => {
      // Update RackView indices
      setRacksCurrentDataIndex((prevIndices) => {
        return prevIndices.map((idx, i) => (idx + 1) % racksDataSets[i].length);
      });
      // Update AthleteView indices
      setRacksDataSetsForAthleteViewIndex((prevIndices) => {
        return prevIndices.map(
          (idx, i) => (idx + 1) % racksDataSetsForAthleteView[i].length,
        );
      });
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  // Update RackView current data
  useEffect(() => {
    setRacksCurrentData(
      racksCurrentDataIndex.map((idx, i) => racksDataSets[i][idx]),
    );
  }, [racksCurrentDataIndex]);

  // Update AthleteView current data
  useEffect(() => {
    // Merge all current athlete view data into one object
    const mergedData: Record<string, any> = {};

    racksDataSetsForAthleteViewIndex.forEach((idx, i) => {
      const currentData = racksDataSetsForAthleteView[i][idx];
      for (const userId of Object.keys(currentData)) {
        mergedData[userId] = currentData[userId];
      }
    });

    setRacksDataSetsForAthleteViewData([mergedData]);
  }, [racksDataSetsForAthleteViewIndex]);

  const renderContentTabs = () => (
    <Box className={styles.tabs}>
      {Object.values(ContentTabs).map((tab) => (
        <Button
          key={tab}
          variant="text"
          color="primary"
          onClick={() => setSelectedTab(tab)}
          className={classNames(styles.tab, {
            [styles.selected]: selectedTab === tab,
          })}
        >
          {tab}
        </Button>
      ))}
    </Box>
  );

  const renderContentHeader = () => {
    const getSubtitleText = () => {
      switch (selectedTab) {
        case ContentTabs.RackView:
          return texts.rackView.subtitle;
        case ContentTabs.AthleteView:
          return texts.athleteView.subtitle;
        case ContentTabs.GroupView:
          return texts.groupView.subtitle;
        default:
          return texts.rackView.subtitle;
      }
    };

    return (
      <div className={styles.header}>
        <div className={styles.tabsContainer}>
          {renderContentTabs()}
          <Button className={styles.button} onClick={() => {}}>
            <OpenInFullIcon />
            <span className={styles.buttonText}>{'Full Screen'}</span>
          </Button>
        </div>
        <span className={styles.subtitle}>{getSubtitleText()}</span>
      </div>
    );
  };

  const renderRackView = () => {
    // Now that we have 20 racks, let's just show them once each
    return (
      <div className={styles.rackView}>
        {racksCurrentData.map((data, index) => (
          <LiveRack key={index} number={index + 1} data={data} />
        ))}
      </div>
    );
  };

  const renderAthleteView = () => {
    const athleteData = racksDataSetsForAthleteViewData[0] || {};
    return (
      <div className={styles.athleteView}>
        <LiveAthleteView data={athleteData} />
      </div>
    );
  };

  const renderGroupView = () => {
    return <div className={styles.groupView}></div>;
  };

  const renderSelectedTabContent = () => {
    switch (selectedTab) {
      case ContentTabs.RackView:
        return renderRackView();
      case ContentTabs.AthleteView:
        return renderAthleteView();
      case ContentTabs.GroupView:
        return renderGroupView();
      default:
        return renderRackView();
    }
  };

  const renderContent = () => (
    <div className={styles.liveWorkouts}>
      {renderContentHeader()}
      {renderSelectedTabContent()}
    </div>
  );

  return (
    <DashboardContentWrapper
      drillInContent={null}
      title={texts.title}
      actions={[
        {
          icon: <AddBoxOutlinedIcon />,
          label: 'Add A Workout',
          onClick: () => {},
        },
      ]}
      onBack={() => {}}
    >
      {renderContent()}
    </DashboardContentWrapper>
  );
};

export default LiveWorkouts;
