import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './ExerciseGroup.module.scss';
import { locales, DragTypes, indexToAlphabetMap } from '../../constants';
import GroupSet from './GroupSet';
import OpenWithOutlinedIcon from '@mui/icons-material/OpenWithOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';

import type { Exercise, ExerciseGroup, SetGroup } from '../../types';

const texts = locales.en.components.workouts.exerciseGroup;

export interface ExerciseGroupProps {
  trainerMergedExercises: Record<string, Exercise>;
  exerciseGroup: ExerciseGroup;
  updateExerciseGroup: (exerciseGroup: ExerciseGroup) => void;
  moveExerciseGroup: (
    groupOriginIndex: number,
    groupTargetIndex: number,
  ) => void;
  onSetGroupMoveToExistingExerciseGroup: (
    draggedExerciseGroupIndex: number,
    draggedSetGroupIndex: number,
    setGroup: SetGroup,
  ) => void;
  onUnifyExerciseGroups: (
    draggedExerciseGroup: ExerciseGroup,
    draggedExerciseGroupIndex: number,
    targetExerciseGroupIndex: number,
  ) => void;
  onExerciseDrop: (
    exercise: Exercise,
    exerciseGroupIndexToRemove?: number,
    groupIndexToRemove?: number,
  ) => void;
  onSetGroupDropFromExerciseGroup: (
    exercise: Exercise,
    exerciseGroupIndexToRemove: number,
    groupIndexToRemove: number,
    newExerciseGroupIndex: number,
    setGroup: SetGroup,
  ) => void;
  onDropExerciseAfterExerciseGroup: (
    exercise: Exercise,
    indexToInsertExerciseGroup: number,
  ) => void;
  exerciseGroupIndex: number;
}

const ExerciseGroupComponent: React.FC<ExerciseGroupProps> = ({
  exerciseGroup,
  trainerMergedExercises,
  moveExerciseGroup,
  onSetGroupMoveToExistingExerciseGroup,
  onDropExerciseAfterExerciseGroup,
  onUnifyExerciseGroups,
  onExerciseDrop,
  onSetGroupDropFromExerciseGroup,
  updateExerciseGroup,
  exerciseGroupIndex,
}) => {
  const [dropZoneVisible, setDropZoneVisible] = useState(false);
  const draggedExerciseGroupIndexRef = React.useRef<number | null>(null);

  const updateSets = (sets: SetGroup, groupSetsIndex: number) => {
    const updatedSets = exerciseGroup.sets
      .map((setGroup, index) => (index === groupSetsIndex ? sets : setGroup))
      .filter((sets) => sets.length > 0);
    updateExerciseGroup({ ...exerciseGroup, sets: updatedSets });
  };

  const onReorderGroupSet = (draggedIndex: number, targetIndex: number) => {
    const newSets = [...exerciseGroup.sets];
    const [movedSet] = newSets.splice(draggedIndex, 1);
    newSets.splice(targetIndex, 0, movedSet);
    updateExerciseGroup({ ...exerciseGroup, sets: newSets });
  };

  const handleExerciseGroupDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData(
      'application/json',
      JSON.stringify({
        type: DragTypes.ExerciseGroupReorder,
        exerciseGroupIndex,
        groupSetIndex: 0,
        exerciseGroup: exerciseGroup,
      }),
    );
    draggedExerciseGroupIndexRef.current = exerciseGroupIndex;
  };

  const handleDropAfterExerciseGroup = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const data = e.dataTransfer.getData('application/json');
    const parsedData = JSON.parse(data);
    const droppedExerciseGroupIndex = parsedData.exerciseGroupIndex;
    switch (parsedData.type) {
      case DragTypes.GroupSetReorder:
        const { exercise, groupSetIndex, setGroup } = parsedData;
        const newExerciseGroupIndex = exerciseGroupIndex + 1;
        onSetGroupDropFromExerciseGroup(
          exercise,
          droppedExerciseGroupIndex,
          groupSetIndex,
          newExerciseGroupIndex,
          setGroup,
        );
        break;
      case DragTypes.ExerciseGroupReorder:
        if (droppedExerciseGroupIndex !== exerciseGroupIndex) {
          moveExerciseGroup(droppedExerciseGroupIndex, exerciseGroupIndex);
        }
        break;
      case DragTypes.DragExercise:
        console.log('Dropped exercise after exercise group');
        console.log('parsedData:', parsedData);
        onDropExerciseAfterExerciseGroup(
          parsedData.exercise,
          exerciseGroupIndex + 1,
        );
        break;
    }
    draggedExerciseGroupIndexRef.current = null;
    setDropZoneVisible(false);
  };

  const renderExerciseGroupHeader = () => {
    return (
      <div className={styles.headerWrapper}>
        <div
          className={styles.exerciseGroupTitle}
        >{`${texts.exerciseGroup} ${indexToAlphabetMap[exerciseGroupIndex] || 'Extra'}`}</div>
        <div
          className={styles.exerciseGroupDragHandle}
          draggable
          onDragStart={handleExerciseGroupDragStart}
          onDragEnd={handleDragEnd}
        >
          <OpenWithOutlinedIcon color="info" />
        </div>
      </div>
    );
  };

  const circuit = exerciseGroup.sets;
  const isCircuit = circuit.length > 1;
  // const isSuperset = circuit.length === 2;

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedExerciseGroupIndexRef.current === exerciseGroupIndex) {
      return;
    }
    setDropZoneVisible(true);
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggedExerciseGroupIndexRef.current === exerciseGroupIndex) {
      return;
    }
    setDropZoneVisible(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setDropZoneVisible(false);
  };

  const handleDragEnd = () => {
    draggedExerciseGroupIndexRef.current = null;
  };

  return (
    <div
      className={styles.exerciseGroup}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {renderExerciseGroupHeader()}
      <div className={styles.exerciseGroupContent}>
        <div className={styles.groupSetsWrapper}>
          {circuit.map((setGroup, index) => (
            <GroupSet
              exerciseGroup={exerciseGroup}
              exerciseGroupIndex={exerciseGroupIndex}
              onReorderGroupSet={onReorderGroupSet}
              key={`groupSet-${exerciseGroup.name}-${index}`}
              groupSetIndex={index}
              updateSets={(newSets) => updateSets(newSets, index)}
              onSetGroupMoveToExistingExerciseGroup={
                onSetGroupMoveToExistingExerciseGroup
              }
              onUnifyExerciseGroups={onUnifyExerciseGroups}
              onExerciseDrop={onExerciseDrop}
              exercises={trainerMergedExercises}
              setGroup={setGroup}
              enableInternalDrag={isCircuit}
            />
          ))}
        </div>
      </div>
      <div
        onDrop={handleDropAfterExerciseGroup}
        className={classNames(styles.dropZone, {
          [styles.dropZoneVisible]: dropZoneVisible,
        })}
      >
        <div className={styles.dropZoneTitleWrapper}>
          <SaveAltIcon color="inherit" />
          <span className={styles.dropZoneTitle}>
            {texts.exerciseGroupReorderDropZone}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ExerciseGroupComponent;
