import React, { useState } from 'react';
import styles from './GymRow.module.scss';
import classNames from 'classnames';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import Menu from '../atoms/Menu';

import type { Gym } from '../../types';

interface GymRowProps {
  gym: Gym;
  selected: boolean;
  onSelect?: () => void;
}

const GymRow: React.FC<GymRowProps> = ({ gym, onSelect, selected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const renderGymName = () => {
    const gymName = gym.name;
    return (
      <div className={classNames(styles.gymColumn, styles.gymName)}>
        {gymName}
      </div>
    );
  };

  const renderGymLogo = () => {
    const logoUrl = gym.blackLogoUrl;
    return (
      <div className={classNames(styles.gymColumn, styles.logoContainer)}>
        <img src={logoUrl} className={styles.image} alt="Gym Logo" />
      </div>
    );
  };

  const renderMoreOptions = () => {
    const menuOptions = [
      {
        onClick: () => onSelect && onSelect(),
        label: 'Gym Settings',
        icon: <SettingsApplicationsIcon color="primary" />,
      },
    ];
    return (
      <div className={classNames(styles.gymColumn, styles.options)}>
        <PendingOutlinedIcon
          // @ts-ignore
          onClick={(event) => setAnchorEl(event.currentTarget)}
          className={styles.optionsBtn}
          color="primary"
        />
        <Menu
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleCloseMenuOptions}
          options={menuOptions}
        />
      </div>
    );
  };

  return (
    <div
      // onClick={onSelect}
      className={classNames(styles.gymRow, styles.listGrid, {
        [styles.selected]: selected,
      })}
    >
      {renderGymName()}
      {renderGymLogo()}
      {renderMoreOptions()}
    </div>
  );
};

export default GymRow;
