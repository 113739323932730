import _ from 'lodash';
import React, { useEffect, useState, useRef } from 'react';
import styles from './EditProgram.module.scss';
import Menu from '../atoms/Menu';
import classNames from 'classnames';
import EmptyState from '../composites/EmptyState';
import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
} from '@mui/material';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import ContentCopy from '@mui/icons-material/ContentCopy';
import AddWorkoutsDialog from './AddWorkoutsDialog';
import AddWorkoutDialog from './AddWorkoutDialog';
import DragIndicatorOutlinedIcon from '@mui/icons-material/DragIndicatorOutlined';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CircleIcon from '@mui/icons-material/Circle';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import ProgramItemRow from './ProgramItemRow';
import type {
  AssignedProgram,
  TrainingRoutineTemplate,
  TrainingRoutine,
  WithOptionalId,
  ProgramItem,
} from '../../types';
import { locales, Day, daysOrder, DragTypes } from '../../constants';

import {
  getYearAheadCalendarWithRealDays,
  getNextDay,
} from '../../utils/utils';
import { useGlobalData } from '../../providers';

const texts = locales.en.components.programs.editProgram;

enum ViewModes {
  LIST = 'list',
  CALENDAR = 'calendar',
}

export interface EditProgramDialogProps {
  isEditMode?: boolean;
  onSubmit: (
    program: WithOptionalId<AssignedProgram>,
  ) => Promise<{ success: boolean; errorMessage?: string }>;
  program: WithOptionalId<AssignedProgram>;
  setProgram: React.Dispatch<
    React.SetStateAction<WithOptionalId<AssignedProgram>>
  >;
  onClose: () => void;
  onEditWorkout: (
    routine: TrainingRoutine,
    weekIndex: number,
    dayIndex: number,
    selectedDay: Day | null,
  ) => void;
}

const EditProgram: React.FC<EditProgramDialogProps> = ({
  isEditMode,
  onClose,
  onSubmit,
  program,
  setProgram,
  onEditWorkout,
}) => {
  const { mergedExercises } = useGlobalData();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isAddWorkoutDialogOpen, setIsAddWorkoutDialogOpen] = useState(false);
  const [isAddWorkoutsDialogOpen, setIsAddWorkoutsDialogOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [weekAnchorEl, setWeekAnchorEl] = useState<null | HTMLElement>(null);
  const weekOptionsOpen = Boolean(weekAnchorEl);
  const [editNameActive, setEditNameActive] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const [name, setName] = useState(program.name || '');
  const [nameError, setNameError] = useState<boolean>(false);

  const [viewMode, setViewMode] = useState<ViewModes>(ViewModes.CALENDAR);
  // const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [selectedDay, setSelectedDay] = useState<Day | null>(null);
  const [hoveredMonth, setHoveredMonth] = useState<number | null>(null);
  const [hoveredWeek, setHoveredWeek] = useState<number | null>(null);
  const [hoveredDay, setHoveredDay] = useState<Day | null>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    nameRef.current?.focus();
  }, []);

  const handleCloseMenuOptions = () => {
    setAnchorEl(null);
  };

  const getWorkoutDayMenuItems = () => {
    const menuItems: Array<{
      onClick: () => void;
      label: string;
      icon: React.ReactNode;
    }> = [];
    menuItems.push({
      onClick: () => {
        const programItemIndex = program.programItems[
          selectedWeekIndex
        ].findIndex((item) => item.day === selectedDay);

        if (programItemIndex === -1) return;
        onEditWorkout(
          program.programItems[selectedWeekIndex][programItemIndex].routine,
          selectedWeekIndex,
          programItemIndex,
          selectedDay,
        );
        handleCloseMenuOptions();
      },
      label: 'Edit Workout Details',
      icon: <BorderColorOutlinedIcon color="primary" />,
    });

    menuItems.push({
      onClick: () => {
        const programItemIndex = program.programItems[
          selectedWeekIndex
        ].findIndex((item) => item.day === selectedDay);

        if (programItemIndex === -1) return;
        onRemoveProgramItem(selectedWeekIndex, programItemIndex);
        handleCloseMenuOptions();
      },
      label: 'Delete Workout',
      icon: <DeleteForeverOutlinedIcon color="primary" />,
    });

    return menuItems;
  };

  const handleWorkoutDayMenuClick = (
    day: Day,
    weekIndex: number,
    event: any,
  ) => {
    setSelectedWeekIndex(weekIndex);
    setSelectedDay(day);
    setAnchorEl(event.currentTarget);
  };

  const renderWorkoutDayMenu = (day: Day, weekIndex: number) => (
    <div className={classNames(styles.fadeInMenu)}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => handleWorkoutDayMenuClick(day, weekIndex, event)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={anchorEl}
        isOpen={open}
        onClose={handleCloseMenuOptions}
        options={getWorkoutDayMenuItems()}
      />
    </div>
  );

  const handleSubmit = async () => {
    if (!name) {
      nameRef.current?.focus();
      return setNameError(true);
    }

    setLoading(true);

    const programDeepClone = _.cloneDeep(program);
    const sortedProgramItemsClone = programDeepClone.programItems.map((week) =>
      week.sort(
        (a, b) =>
          daysOrder.indexOf(a.day as Day) - daysOrder.indexOf(b.day as Day),
      ),
    );

    const sanitizedProgram: WithOptionalId<AssignedProgram> = {
      ...program,
      programItems: sortedProgramItemsClone,
    };

    // console.log('sanitizedProgram', sanitizedProgram);

    if (sanitizedProgram.programItems.length === 0) {
      setErrorMessage('You must fill at least week workouts.');
      setLoading(false);
      return;
    }
    const { success, errorMessage } = await onSubmit(sanitizedProgram);
    if (success) {
      onClose();
    } else {
      setErrorMessage(errorMessage || 'An error occurred. Please try again.');
      setLoading(false);
    }
  };

  const handleAddWorkoutsFromTemplates = (
    workouts: TrainingRoutineTemplate[],
  ) => {
    setProgram((prevProgram) => {
      let updatedProgram = { ...prevProgram };
      if (updatedProgram.programItems.length - 1 < selectedWeekIndex) {
        const newWeeks = Array.from(
          {
            length: selectedWeekIndex - updatedProgram.programItems.length + 1,
          },
          () => [],
        );
        updatedProgram = {
          ...updatedProgram,
          programItems: [...updatedProgram.programItems, ...newWeeks],
        };
      }
      const newRoutines = workouts.map(({ routine }) => ({
        routine,
        day: selectedDay,
      }));
      const updatedProgramItems =
        updatedProgram.programItems.length === 0
          ? [newRoutines]
          : updatedProgram.programItems.map((week, index) => {
              if (index === selectedWeekIndex) {
                return [...(week || []), ...newRoutines];
              }
              return week;
            });
      return {
        ...updatedProgram,
        programItems: updatedProgramItems,
      };
    });
  };

  const renderAddWorkoutDialog = () => {
    if (!isAddWorkoutDialogOpen) return null;
    return (
      <AddWorkoutDialog
        onCreate={() => {
          onEditWorkout(
            { name: '', exerciseGroups: [] } as TrainingRoutine,
            selectedWeekIndex,
            program.programItems?.[selectedWeekIndex]?.length || 0,
            selectedDay,
          );
        }}
        onAdd={() => {
          setIsAddWorkoutDialogOpen(false);
          setIsAddWorkoutsDialogOpen(true);
        }}
        onClose={() => {
          setSelectedDay(null);
          setIsAddWorkoutDialogOpen(false);
        }}
      />
    );
  };

  const renderAddWorkoutsDialog = () => {
    if (!isAddWorkoutsDialogOpen) return null;
    return (
      <AddWorkoutsDialog
        onSubmit={(workouts: TrainingRoutineTemplate[]) => {
          handleAddWorkoutsFromTemplates(workouts);
          setIsAddWorkoutsDialogOpen(false);
        }}
        onClose={() => setIsAddWorkoutsDialogOpen(false)}
        singleSelect={true}
      />
    );
  };

  const getWeekOptions = () => [
    {
      // TODO: Fix this so duplicate will first get the current week index and not the old state
      onClick: () => {
        setProgram((prevProgram) => ({
          ...prevProgram,
          programItems: [
            ...prevProgram.programItems.slice(0, selectedWeekIndex + 1),
            // filter out the routine id
            prevProgram.programItems[selectedWeekIndex].map((programItem) => ({
              routine: { ...programItem.routine },
              day: programItem.day,
            })),
            ...prevProgram.programItems.slice(selectedWeekIndex + 1),
          ],
        }));
        setWeekAnchorEl(null);
        setSelectedWeekIndex(
          (prevSelectedWeekIndex) => prevSelectedWeekIndex + 1,
        );
      },
      label: 'Duplicate Week',
      icon: <ContentCopy color="primary" />,
    },
    {
      onClick: () => {
        setProgram((prevProgram) => ({
          ...prevProgram,
          programItems: prevProgram.programItems.filter(
            (_, index) => index !== selectedWeekIndex,
          ),
        }));
        setWeekAnchorEl(null);
        setSelectedWeekIndex(
          (prevSelectedWeekIndex) => prevSelectedWeekIndex - 1,
        );
      },
      label: 'Delete Week',
      icon: <DeleteForeverOutlinedIcon color="primary" />,
    },
  ];

  const renderWeekOptions = () => (
    <div className={styles.weekOptions}>
      <PendingOutlinedIcon
        // @ts-ignore
        onClick={(event) => setWeekAnchorEl(event.currentTarget)}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={weekAnchorEl}
        isOpen={weekOptionsOpen}
        onClose={() => setWeekAnchorEl(null)}
        options={getWeekOptions()}
      />
    </div>
  );

  const handleClickWeekMenu = (hoveredWeekIndex: number, event: any) => {
    setWeekAnchorEl(event.currentTarget);
    setSelectedWeekIndex(hoveredWeekIndex);
  };

  const renderWeekMenu = (hoveredWeekIndex: number) => (
    <div className={styles.fadeInMenu}>
      <PendingOutlinedIcon
        onClick={(event) => {
          handleClickWeekMenu(hoveredWeekIndex, event);
        }}
        className={styles.optionsBtn}
        color="primary"
      />
      <Menu
        anchorEl={weekAnchorEl}
        isOpen={weekOptionsOpen}
        onClose={() => setWeekAnchorEl(null)}
        options={getWeekOptions()}
      />
    </div>
  );

  const addWeek = () => {
    setProgram((prevProgram) => ({
      ...prevProgram,
      programItems: [...prevProgram.programItems, []],
    }));
  };

  const onRemoveProgramItem = (weekIndex: number, programItemIndex: number) => {
    setProgram((prevProgram) => {
      const updatedProgramItems = prevProgram.programItems.map(
        (week, index) => {
          if (index === weekIndex) {
            return week.filter(
              (_, itemIndex) => itemIndex !== programItemIndex,
            );
          }
          return week;
        },
      );

      return {
        ...prevProgram,
        programItems: updatedProgramItems,
      };
    });
  };

  const handleDragStart = (event: React.DragEvent, draggedIndex: number) => {
    event.dataTransfer.setData(
      'application/json',
      JSON.stringify({ draggedIndex, type: DragTypes.ReorderProgramItem }),
    );
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent, droppedIndex: number) => {
    event.preventDefault();
    const { draggedIndex, type } = JSON.parse(
      event.dataTransfer.getData('application/json'),
    );
    if (type !== DragTypes.ReorderProgramItem) return;
    if (!_.isNumber(draggedIndex)) return;

    if (draggedIndex !== droppedIndex) {
      setProgram((prevProgram) => {
        const newWeekItems = [...prevProgram.programItems[selectedWeekIndex]];
        const [movedItem] = newWeekItems.splice(draggedIndex, 1);
        newWeekItems.splice(droppedIndex, 0, movedItem);

        const updatedProgramItems = prevProgram.programItems.map(
          (week, index) => (index === selectedWeekIndex ? newWeekItems : week),
        );

        return {
          ...prevProgram,
          programItems: updatedProgramItems,
        };
      });
    }
  };

  const onDuplicateProgramItem = (
    weekIndex: number,
    programItemIndex: number,
  ) => {
    setProgram((prevProgram) => {
      const updatedProgramItems = prevProgram.programItems.map(
        (week, index) => {
          if (index === weekIndex) {
            const itemToDuplicate = week[programItemIndex];
            return [
              ...week.slice(0, programItemIndex + 1),
              { ...itemToDuplicate },
              ...week.slice(programItemIndex + 1),
            ];
          }
          return week;
        },
      );

      return {
        ...prevProgram,
        programItems: updatedProgramItems,
      };
    });
  };

  const renderListView = () => {
    return (
      <div className={styles.editProgram}>
        <div className={styles.leftContent}>
          <div className={styles.weekList}>
            <div className={styles.weekListTitle}>Program Schedule</div>
            {program.programItems.map((week, index) => (
              <div
                key={`week-${index}-${program.programItems.length}`}
                className={classNames(styles.week, {
                  [styles.selectedWeek]: selectedWeekIndex === index,
                })}
                onClick={() => setSelectedWeekIndex(index)}
              >
                <DragIndicatorOutlinedIcon
                  color={index === selectedWeekIndex ? 'primary' : 'info'}
                />
                <span className={styles.weekText}>Week {index + 1}</span>
                {renderWeekOptions()}
              </div>
            ))}
            <Button className={styles.addWeek} onClick={addWeek}>
              <>
                <AddBoxOutlinedIcon color="info" />
                <span className={styles.addWeekText}>Add Week</span>
              </>
            </Button>
          </div>
        </div>
        <div
          className={classNames(styles.editProgramContent, styles.rightContent)}
        >
          <div className={styles.rightContentHeaderRow}>
            <div className={styles.rightContentWorkoutsTitle}>Workouts</div>
            {program.programItems[selectedWeekIndex]?.length > 0 && (
              <Button
                className={styles.addWorkout}
                onClick={() =>
                  onAddWorkoutFromCalendar(
                    selectedWeekIndex,
                    getNextDay(program.programItems[selectedWeekIndex]),
                  )
                }
              >
                <AddBoxOutlinedIcon color="primary" />
                <span className={styles.addWorkoutText}>Add Workout</span>
              </Button>
            )}
          </div>
          <div className={styles.programContent}>
            {!program.programItems[selectedWeekIndex]?.length ? (
              <EmptyState
                icon={<SummarizeOutlinedIcon />}
                cta={{
                  icon: <AddBoxOutlinedIcon color="primary" />,
                  label: 'Add A Workout',
                  onClick: () =>
                    onAddWorkoutFromCalendar(selectedWeekIndex, Day.Monday),
                }}
                text="No Workouts Have Been Added"
              />
            ) : (
              <div className={styles.weekDescription}>
                {program.programItems[selectedWeekIndex].map(
                  ({ routine }, index) => (
                    <ProgramItemRow
                      key={`programItem-${routine.name}-${index}`}
                      onRemove={() =>
                        onRemoveProgramItem(selectedWeekIndex, index)
                      }
                      onDuplicate={() =>
                        onDuplicateProgramItem(selectedWeekIndex, index)
                      }
                      onEdit={() => {
                        onEditWorkout(routine, selectedWeekIndex, index, null);
                      }}
                      index={index}
                      onDragStart={handleDragStart}
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                      routine={routine}
                    />
                  ),
                )}
              </div>
            )}
          </div>
          <div className={styles.rightContentFooter}>
            <Button
              className={classNames(styles.submit, styles.action)}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="info" />
              ) : (
                <span className={styles.submitText}>Save Program</span>
              )}
            </Button>
            <Button
              className={classNames(styles.cancel, styles.action)}
              onClick={onClose}
              disabled={loading}
            >
              <span className={styles.cancelText}>Cancel</span>
            </Button>
          </div>
        </div>
        {errorMessage && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setErrorMessage('')}
          >
            <Alert onClose={() => setErrorMessage('')} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  };

  const onAddWorkoutFromCalendar = (weekIndex: number, day: Day | null) => {
    if (day === null) return;
    setSelectedWeekIndex(weekIndex);
    setSelectedDay(day);
    setIsAddWorkoutDialogOpen(true);
  };

  const isDayToday = (dayNumber: number | undefined, monthNumber: number) => {
    if (!dayNumber || !monthNumber) return false;
    const today = new Date();
    const todayNumber = today.getDate();
    const todayMonthNumber = today.getMonth();
    return todayNumber === dayNumber && todayMonthNumber === monthNumber;
  };

  const renderCalendarDay = (
    dayIndex: number,
    weekIndex: number,
    day: Day,
    programItem?: ProgramItem,
    dayNumber?: number,
    lastDayOfWeekDayNumber?: number,
    isFirstWeek?: boolean,
    isLastWeek?: boolean,
    monthIndex?: number,
  ) => {
    const routine = programItem?.routine;

    const hideDay =
      dayNumber &&
      lastDayOfWeekDayNumber &&
      ((dayNumber > lastDayOfWeekDayNumber && isFirstWeek) ||
        (dayNumber <= lastDayOfWeekDayNumber && isLastWeek));

    const programStartDate = program.startDate
      ? new Date(program.startDate)
      : new Date();

    const isSelectedDay = isDayToday(
      dayNumber,
      (monthIndex || 0) + programStartDate.getMonth(),
    );
    const isStartDay =
      programStartDate.getDate() === dayNumber && monthIndex === 0;

    return (
      <div
        className={styles.calendarDayWrapper}
        key={`month-${monthIndex}-week-${weekIndex}-day-${dayIndex}`}
      >
        <div className={styles.calendarDayHeader}>
          {dayNumber && (
            <div className={styles.calendarDayTitle}>{dayNumber}</div>
          )}
          <div className={styles.calendarDayTitle}>
            {day.toLocaleUpperCase()}
          </div>
        </div>
        {hideDay ? (
          <div
            className={classNames(styles.calendarDay, {
              [styles.selectedDay]: isSelectedDay,
              [styles.startDay]: isStartDay,
            })}
          >
            <div className={styles.emptyDay}></div>
          </div>
        ) : programItem ? (
          <div
            className={classNames(styles.calendarDay, {
              [styles.selectedDay]: isSelectedDay,
              [styles.startDay]: isStartDay,
            })}
          >
            {routine ? (
              <div
                className={styles.calendarDayContent}
                onMouseEnter={() => {
                  setHoveredDay(day);
                }}
                onMouseLeave={() => {
                  setHoveredDay(null);
                }}
              >
                <div className={styles.descriptionContainer}>
                  <div className={styles.routineTitle}>{routine.name}</div>
                  {hoveredDay === day &&
                    hoveredWeek === weekIndex &&
                    (!hoveredMonth || hoveredMonth === monthIndex) &&
                    renderWorkoutDayMenu(day, weekIndex)}
                </div>
                <div className={styles.descriptionContainer}>
                  <div className={styles.calendarDayDescription}>
                    <FitnessCenterIcon
                      color="primary"
                      style={{
                        fontSize: 11,
                        marginRight: 4,
                        opacity: 0.6,
                      }}
                    />
                    {routine.exerciseGroups.length} exercises
                  </div>
                  {routine.estimatedDuration && (
                    <div className={styles.calendarDayDescription}>
                      <WatchLaterIcon
                        color="primary"
                        style={{
                          fontSize: 11,
                          marginRight: 4,
                          opacity: 0.6,
                        }}
                      />
                      {routine.estimatedDuration} min
                    </div>
                  )}
                </div>
                <div className={styles.calendarDayExercisesContainer}>
                  {routine.exerciseGroups.map((group, index) => (
                    <div
                      className={styles.calendarDayExerciseGroupContainer}
                      key={`exerciseGroup-${group.name}-${index}`}
                    >
                      {group.sets.length === 2 && (
                        <div className={styles.text}>SUPERSET</div>
                      )}
                      {group.sets.length > 2 && (
                        <div className={styles.text}>CIRCUIT</div>
                      )}
                      <div className={styles.calendarDayExerciseGroup}>
                        {group.sets.map((setGroup, setGroupIndex) => (
                          <div
                            key={`exerciseSet-${setGroupIndex}`}
                            className={styles.calendarDayExerciseSet}
                          >
                            {setGroupIndex > 0 && (
                              <div className={styles.calendarDayDivider} />
                            )}
                            <div
                              className={styles.calendarDayExerciseGroupName}
                            >
                              {
                                Object.values(mergedExercises).find(
                                  (exercise) =>
                                    exercise.id === setGroup[0].exerciseId,
                                )?.name
                              }
                            </div>
                            <div
                              className={styles.calendarDayExerciseGroupOptions}
                            >
                              <div
                                className={
                                  styles.calendarDayExerciseGroupOptionText
                                }
                              >
                                {`Sets: ${setGroup.length}`}
                              </div>

                              <CircleIcon
                                color="primary"
                                style={{
                                  fontSize: 6,
                                  opacity: 0.6,
                                }}
                              />
                              <div
                                className={
                                  styles.calendarDayExerciseGroupOptionText
                                }
                              >
                                {`Reps: ${setGroup[0].reps}`}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div
            className={classNames(styles.calendarDay, {
              [styles.selectedDay]: isSelectedDay,
              [styles.startDay]: isStartDay,
            })}
          >
            <div className={styles.emptyDay}>
              <Button
                className={styles.addButton}
                onClick={() => onAddWorkoutFromCalendar(weekIndex, day)}
              >
                <AddBoxOutlinedIcon color="primary" />
                <span className={styles.addButtonText}>Add Workout</span>
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderCalendarTemplatedWeek = (
    week: ProgramItem[],
    weekIndex: number,
  ) => {
    return (
      <div
        className={styles.calendarWeekWrapper}
        key={`week-${weekIndex}`}
        onMouseEnter={() => setHoveredWeek(weekIndex)}
        onMouseLeave={() => setHoveredWeek(null)}
      >
        <div className={styles.calendarWeekHeader}>
          <div className={styles.calendarWeekTitle}>Week {weekIndex + 1}</div>
        </div>
        <div className={styles.weekContainer}>
          <div className={styles.calendarWeek}>
            <div className={styles.calendarWeekContent}>
              {Object.values(Day).map((day, dayIndex) => {
                const programItem = week?.find((item) => item.day === day);
                return renderCalendarDay(dayIndex, weekIndex, day, programItem);
              })}
            </div>
          </div>
          <div className={styles.container}>
            {hoveredWeek === weekIndex && renderWeekMenu(hoveredWeek)}
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarTemplateWeeksView = () => {
    return (
      <div className={styles.calendarWeeks}>
        {program.programItems.map((week, index) =>
          renderCalendarTemplatedWeek(week, index),
        )}
      </div>
    );
  };

  const renderCalendarWeek = (
    programItems: ProgramItem[],
    monthIndex: number,
    weekIndex: number,
    week: number[],
    isFirstWeek: boolean,
    isLastWeek: boolean,
  ) => {
    return (
      <div
        className={styles.calendarWeekWrapper}
        key={`month-${monthIndex}-week-${weekIndex}`}
        onMouseEnter={() => setHoveredWeek(weekIndex)}
        onMouseLeave={() => setHoveredWeek(null)}
      >
        <div className={styles.calendarWeekHeader}>
          {/* Enable to render title */}
          {/* <div className={styles.calendarWeekTitle}>Week {weekIndex + 1}</div> */}
        </div>
        <div className={styles.weekContainer}>
          <div
            className={styles.calendarWeek}
            key={`month-${monthIndex}-week-${weekIndex}-content`}
          >
            <div className={styles.calendarWeekContent}>
              {week.map((dayNumber, dayIndex) => {
                const day = Object.values(Day)[dayIndex];
                const programItem = programItems.find(
                  (item) => item.day === day,
                );
                return renderCalendarDay(
                  dayIndex,
                  weekIndex,
                  day,
                  programItem,
                  dayNumber,
                  week[week.length - 1],
                  isFirstWeek && !isLastWeek,
                  isLastWeek,
                  monthIndex,
                );
              })}
            </div>
          </div>
          <div className={styles.container}>
            {hoveredWeek === weekIndex &&
              monthIndex === hoveredMonth &&
              renderWeekMenu(hoveredWeek)}
          </div>
        </div>
      </div>
    );
  };

  const renderCalendarWeeksView = () => {
    const startDate = program.startDate
      ? new Date(program.startDate)
      : new Date();
    const startMonth = startDate.getMonth();
    const calendar = getYearAheadCalendarWithRealDays(startDate);

    const today = new Date();
    const currentMonth = today.getMonth();

    let programWeekIndex = 0;
    const getProgramWeekIndex = (duplicateProgramWeek?: boolean) => {
      if (duplicateProgramWeek) {
        return programWeekIndex - 1;
      }
      const index = programWeekIndex;
      programWeekIndex++;
      return index;
    };

    return (
      <div className={styles.calendarWeeks}>
        {calendar.map((month, monthIndex) => {
          const monthRealNumber = startMonth + monthIndex;
          const monthName = new Date(0, monthRealNumber).toLocaleString(
            'default',
            { month: 'long' },
          );

          const isOverlappingPrevMonth =
            monthIndex - 1 >= 0 &&
            calendar[monthIndex][0][0] ===
              calendar[monthIndex - 1][calendar[monthIndex - 1].length - 1][0];

          const shouldRenderMonth = monthRealNumber >= currentMonth;
          if (!shouldRenderMonth) {
            month.map((week, monthlyWeekIndex) => {
              return getProgramWeekIndex(
                isOverlappingPrevMonth && monthlyWeekIndex === 0,
              );
            });
          }

          return shouldRenderMonth ? (
            <div
              key={monthIndex}
              onMouseEnter={() => setHoveredMonth(monthIndex)}
              onMouseLeave={() => setHoveredMonth(null)}
            >
              <div className={styles.calendarMonthHeader}>
                <div className={styles.calendarMonthTitle}>{monthName}</div>
              </div>
              {month.map((week, monthlyWeekIndex) => {
                const weekIndex = getProgramWeekIndex(
                  isOverlappingPrevMonth && monthlyWeekIndex === 0,
                );
                return renderCalendarWeek(
                  program.programItems[weekIndex] || [],
                  monthIndex,
                  weekIndex,
                  week,
                  monthlyWeekIndex === 0,
                  monthlyWeekIndex === month.length - 1,
                );
              })}
            </div>
          ) : null;
        })}
      </div>
    );
  };

  const renderAddWeekButton = () => {
    return (
      <Button className={styles.addWeekButton} onClick={addWeek}>
        <>
          <AddBoxOutlinedIcon color="primary" />
          <span className={styles.addWeekButtonText}>Add Week</span>
        </>
      </Button>
    );
  };

  const renderCalendarView = () => {
    return (
      <div className={styles.editProgram}>
        <div className={classNames(styles.calendarView)}>
          <div className={styles.programContent}>
            {isEditMode
              ? renderCalendarWeeksView()
              : renderCalendarTemplateWeeksView()}
            {!isEditMode && renderAddWeekButton()}
          </div>
          <div className={styles.rightContentFooter}>
            <Button
              className={classNames(styles.submit, styles.action)}
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress size={24} color="info" />
              ) : (
                <span className={styles.submitText}>Save Program</span>
              )}
            </Button>
            <Button
              className={classNames(styles.cancel, styles.action)}
              onClick={onClose}
              disabled={loading}
            >
              <span className={styles.cancelText}>Cancel</span>
            </Button>
          </div>
        </div>
        {errorMessage && (
          <Snackbar
            open={true}
            autoHideDuration={6000}
            onClose={() => setErrorMessage('')}
          >
            <Alert onClose={() => setErrorMessage('')} severity="error">
              {errorMessage}
            </Alert>
          </Snackbar>
        )}
      </div>
    );
  };

  const renderView = () => {
    switch (viewMode) {
      case ViewModes.LIST:
        return renderListView();
      case ViewModes.CALENDAR:
        return renderCalendarView();
      default:
        return renderCalendarView();
    }
  };

  const renderViewHeader = () => {
    return (
      <div className={styles.rightContentHeader}>
        <div className={styles.rightContentHeaderRow}>
          <div className={styles.programName}>
            <div className={styles.programNameHeader}>
              <span className={styles.programNameTitle}>PROGRAM NAME:</span>
              <div
                className={styles.programNameEdit}
                onClick={() => {
                  setEditNameActive(true);
                  nameRef.current?.select();
                }}
              >
                <DriveFileRenameOutlineOutlinedIcon color="info" />
              </div>
            </div>
            <div className={styles.programNameContent}>
              <TextField
                fullWidth
                inputRef={nameRef}
                color="primary"
                name="workoutName"
                placeholder={texts.titlePlaceholder}
                value={name}
                className={classNames(styles.nameTextField, {
                  [styles.editedName]: editNameActive,
                  [styles.nameError]: nameError,
                })}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setNameError(false);
                  }
                  setName(e.target.value);
                }}
                onFocus={() => setEditNameActive(true)}
                onBlur={() => {
                  setEditNameActive(false);
                  setProgram((prevProgram) => ({
                    ...prevProgram,
                    name,
                  }));
                }}
                sx={{
                  '& .MuiInputBase-input::placeholder': {
                    color: 'white', // Replace with your desired color
                    // fontStyle: 'italic', // Additional styling
                    fontSize: '14px',
                  },
                }}
              />
            </div>
          </div>
          {viewMode === ViewModes.LIST ? (
            <div className={styles.weekNumber}>
              Week {selectedWeekIndex + 1}
            </div>
          ) : null}
        </div>
        <div className={styles.toggleViewButtons}>
          <Button
            className={classNames(styles.toggleViewButton, {
              [styles.selectedView]: viewMode === ViewModes.CALENDAR,
            })}
            onClick={() => setViewMode(ViewModes.CALENDAR)}
          >
            <CalendarMonthIcon color="primary" />
          </Button>
          <Button
            className={classNames(styles.toggleViewButton, {
              [styles.selectedView]: viewMode === ViewModes.LIST,
            })}
            onClick={() => setViewMode(ViewModes.LIST)}
          >
            <FormatListBulletedIcon color="primary" />
          </Button>
        </div>
      </div>
    );
  };

  // console.log('program on render: ', program);

  return (
    <div className={styles.editProgramWrapper}>
      {renderViewHeader()}
      {renderView()}
      {renderAddWorkoutDialog()}
      {renderAddWorkoutsDialog()}
    </div>
  );
};

export default EditProgram;
