import React, { useEffect, useRef, useState } from 'react';
import styles from './LiveAthleteView.module.scss';
import classNames from 'classnames';
import { AnalyzedMetrics } from '../../types/automatic';
import { VelocityRate } from '../../constants';
import { useGlobalData } from '../../providers';
import RepInstance from './RepInstance';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CheckIcon from '@mui/icons-material/Check';

// TODO: Replace with real members
import { members } from '../../mock/liveWorkouts';

function classifyConcentric(upDuration: number, minC: number, maxC: number) {
  if (upDuration == null || minC == null || maxC == null)
    return VelocityRate.Good;
  if (upDuration < minC) return VelocityRate.Fast;
  if (upDuration > maxC) return VelocityRate.Slow;
  return VelocityRate.Good;
}

function classifyEccentric(downDuration: number, minE: number, maxE: number) {
  if (downDuration == null || minE == null || maxE == null)
    return VelocityRate.Good;
  if (downDuration < minE) return VelocityRate.Fast;
  if (downDuration > maxE) return VelocityRate.Slow;
  return VelocityRate.Good;
}

interface LiveAthleteViewProps {
  data: Record<string, AnalyzedMetrics[]>;
}

interface SetInfo {
  userId: string;
  metrics: AnalyzedMetrics[];
  rackNumber: number;
  performedSets: number;
  setId: string; // unique identifier for this particular set
}

/**
 * Determine if a set is completed or not and its color.
 * Returns {statusLabel, statusColor}.
 */
function getSetStatus(
  isActive: boolean,
  performedCount: number,
  totalRepsNeeded: number,
  fastCount: number,
  slowCount: number,
  goodCount: number,
): {
  statusLabel: string;
  statusColor: 'orange' | 'white' | 'green' | 'yellow' | 'red';
} {
  let statusLabel = 'In Progress';
  let statusColor: 'orange' | 'white' | 'green' | 'yellow' | 'red' = 'orange';

  const totalAttempts = goodCount + fastCount + slowCount;

  if (isActive) {
    if (performedCount < totalRepsNeeded) {
      statusLabel = 'In Progress';
      statusColor = 'orange';
    } else {
      // Completed scenario
      if (totalAttempts > 0) {
        const g = goodCount / totalAttempts;
        const f = fastCount / totalAttempts;
        const s = slowCount / totalAttempts;
        if (g > f && g > s) {
          statusLabel = 'Completed';
          statusColor = 'green';
        } else if (f > g && f > s) {
          statusLabel = 'Completed';
          statusColor = 'yellow';
        } else if (s > g && s > f) {
          statusLabel = 'Completed';
          statusColor = 'red';
        } else {
          statusLabel = 'Completed';
          statusColor = 'green';
        }
      } else {
        statusLabel = 'Completed';
        statusColor = 'green';
      }
    }
  } else {
    // Not active
    if (performedCount < totalRepsNeeded) {
      statusLabel = 'Not Completed';
      statusColor = 'white';
    } else {
      // Completed scenario (not active means someone else took rack)
      if (totalAttempts > 0) {
        const g = goodCount / totalAttempts;
        const f = fastCount / totalAttempts;
        const s = slowCount / totalAttempts;
        if (g > f && g > s) {
          statusLabel = 'Completed';
          statusColor = 'green';
        } else if (f > g && f > s) {
          statusLabel = 'Completed';
          statusColor = 'yellow';
        } else if (s > g && s > f) {
          statusLabel = 'Completed';
          statusColor = 'red';
        } else {
          statusLabel = 'Completed';
          statusColor = 'green';
        }
      } else {
        statusLabel = 'Completed';
        statusColor = 'green';
      }
    }
  }

  return { statusLabel, statusColor };
}

/**
 * Generate a unique ID for a set.
 */
function generateSetId(
  userId: string,
  rackNumber: number,
  performedSets: number,
) {
  // combine with a unique timestamp or counter
  return `${userId}-${rackNumber}-${performedSets}-${Date.now()}-${Math.random()}`;
}

/**
 * Extract a list of SetInfo objects from the given data.
 * Each user in data represents one active set (if metrics > 0).
 */
function extractCurrentSets(
  data: Record<string, AnalyzedMetrics[]>,
  prevLiveSets: SetInfo[],
): SetInfo[] {
  const sets: SetInfo[] = [];

  for (const userId of Object.keys(data)) {
    const metrics = data[userId] || [];
    if (metrics.length === 0) continue;

    const lastFrame = metrics[metrics.length - 1];
    const rackNumber = lastFrame.rackNumber ?? 1;
    const performedSets = lastFrame.preset?.performedSets ?? 1;

    // Check if we already have a set in prevLiveSets with same userId, rackNumber, and performedSets
    const existing = prevLiveSets.find(
      (s) =>
        s.userId === userId &&
        s.rackNumber === rackNumber &&
        s.performedSets === performedSets,
    );

    const setId = existing
      ? existing.setId
      : generateSetId(userId, rackNumber, performedSets);

    sets.push({ userId, metrics, rackNumber, performedSets, setId });
  }

  return sets;
}

const LiveAthleteView: React.FC<LiveAthleteViewProps> = ({ data }) => {
  const { mergedExercises } = useGlobalData();

  const [liveSets, setLiveSets] = useState<SetInfo[]>([]);
  const [finishedSets, setFinishedSets] = useState<SetInfo[]>([]);
  const prevDataRef = useRef<Record<string, AnalyzedMetrics[]>>({});

  useEffect(() => {
    const currentSets = extractCurrentSets(data, liveSets);

    // Make a map of current sets by rackNumber
    const rackMap: Record<number, SetInfo> = {};
    for (const s of currentSets) {
      rackMap[s.rackNumber] = s;
    }

    setLiveSets((prevLive) => {
      const newLive: SetInfo[] = [...prevLive];

      // Handle removal or replacement:
      for (let i = newLive.length - 1; i >= 0; i--) {
        const oldSet = newLive[i];
        const replacement = rackMap[oldSet.rackNumber];
        if (!replacement || replacement.userId !== oldSet.userId) {
          // oldSet no longer active
          setFinishedSets((prevFinished) => [oldSet, ...prevFinished]);
          newLive.splice(i, 1);
        }
      }

      // Add or update new sets
      for (const newSet of currentSets) {
        const existingIndex = newLive.findIndex(
          (ls) =>
            ls.userId === newSet.userId &&
            ls.rackNumber === newSet.rackNumber &&
            ls.performedSets === newSet.performedSets,
        );

        if (existingIndex > -1) {
          // Update metrics
          newLive[existingIndex] = { ...newSet };
        } else {
          // New set detected
          // If user had previous set with same rackNumber, move it to finished
          const oldUserIndex = newLive.findIndex(
            (ls) =>
              ls.userId === newSet.userId &&
              ls.rackNumber === newSet.rackNumber &&
              ls.setId !== newSet.setId,
          );
          if (oldUserIndex > -1) {
            const oldSet = newLive[oldUserIndex];
            setFinishedSets((prevFinished) => [oldSet, ...prevFinished]);
            newLive.splice(oldUserIndex, 1);
          }

          // Insert new set at top
          newLive.unshift(newSet);
        }
      }

      return newLive;
    });

    prevDataRef.current = data;
  }, [data]);

  const renderSetRow = (setInfo: SetInfo, isActive: boolean) => {
    const { userId, metrics, setId } = setInfo;
    const member = members.find((m) => m.id === userId);
    const memberName = member
      ? `${member.firstName} ${member.lastName}`
      : `User ${userId}`;
    const lastFrame = metrics[metrics.length - 1];
    const rackNumber = lastFrame.rackNumber ?? 1;
    const preset = lastFrame.preset || null;
    const totalRepsNeeded = preset?.reps ?? 10;
    const performedCount = metrics.length;

    let fastCount = 0;
    let slowCount = 0;
    let goodCount = 0;
    let loggedWeight = 0;

    metrics.forEach((frame) => {
      const {
        minConcentric,
        maxConcentric,
        minEccentric,
        maxEccentric,
        weight,
      } = frame.preset || {};
      if (weight != null) loggedWeight = weight;

      const cClass = classifyConcentric(
        frame.upDuration!,
        minConcentric!,
        maxConcentric!,
      );
      const eClass = classifyEccentric(
        frame.downDuration!,
        minEccentric!,
        maxEccentric!,
      );

      [cClass, eClass].forEach((cls) => {
        if (cls === VelocityRate.Fast) fastCount++;
        else if (cls === VelocityRate.Slow) slowCount++;
        else goodCount++;
      });
    });

    const { statusLabel, statusColor } = getSetStatus(
      isActive,
      performedCount,
      totalRepsNeeded,
      fastCount,
      slowCount,
      goodCount,
    );

    let exerciseName = 'Unknown Exercise';
    if (
      metrics.length > 0 &&
      metrics[0].exerciseId &&
      mergedExercises[metrics[0].exerciseId]
    ) {
      exerciseName = mergedExercises[metrics[0].exerciseId].name;
    }

    const repsToShow = Math.max(totalRepsNeeded, performedCount);
    const repItems = [];
    for (let i = 0; i < repsToShow; i++) {
      const frame = metrics[i];
      let eClass = VelocityRate.Good,
        cClass = VelocityRate.Good;
      const showData = !!frame;
      if (showData) {
        const { minConcentric, maxConcentric, minEccentric, maxEccentric } =
          frame.preset || {};
        cClass = classifyConcentric(
          frame.upDuration!,
          minConcentric!,
          maxConcentric!,
        );
        eClass = classifyEccentric(
          frame.downDuration!,
          minEccentric!,
          maxEccentric!,
        );
      }

      repItems.push(
        <RepInstance
          key={i}
          repNumber={i + 1}
          eccentricClass={showData ? eClass : VelocityRate.Empty}
          concentricClass={showData ? cClass : VelocityRate.Empty}
        />,
      );
    }

    return (
      <div
        className={styles.userRow}
        key={setId} // use unique setId now
      >
        {/* Header */}
        <div className={styles.header}>
          <span className={styles.userName}>{memberName}</span>
          <span className={styles.separatorDot}>•</span>
          <span className={styles.rackNumber}>{`Rack: ${rackNumber}`}</span>
          {fastCount > 0 && (
            <span
              className={classNames(styles.attempts, {
                [styles.fast]: fastCount > 0,
              })}
            >
              <WarningAmberOutlinedIcon
                className={styles.warningIcon}
                style={{ color: 'yellow', fontSize: '24px' }}
              />
              {` ${fastCount} Attempts`}
            </span>
          )}
          {slowCount > 0 && (
            <span
              className={classNames(styles.attempts, {
                [styles.slow]: slowCount > 0,
              })}
            >
              <WarningAmberOutlinedIcon
                className={styles.warningIcon}
                style={{ color: 'red', fontSize: '24px' }}
              />
              {` ${slowCount} Attempts`}
            </span>
          )}
          <div
            className={classNames(styles.statusLabel, {
              [styles.orangeBorder]: statusColor === 'orange',
              [styles.whiteBorder]: statusColor === 'white',
              [styles.greenBorder]: statusColor === 'green',
              [styles.yellowBorder]: statusColor === 'yellow',
              [styles.redBorder]: statusColor === 'red',
            })}
          >
            {statusLabel === 'Completed' && (
              <CheckIcon
                style={{ fontSize: '18px', marginRight: '4px', color: '#fff' }}
              />
            )}
            {statusLabel}
          </div>
        </div>
        {/* Main Content */}
        <div className={styles.mainContent}>
          <div className={styles.leftSide}>
            <div className={styles.exerciseName}>{exerciseName}</div>
            <div className={styles.loggedWeight}>Logged Weight:</div>
            <div className={styles.loggedWeightValue}>{loggedWeight} lbs</div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.repList}>{repItems}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {liveSets.map((setInfo) => renderSetRow(setInfo, true))}
      {finishedSets.map((setInfo) => renderSetRow(setInfo, false))}
    </div>
  );
};

export default LiveAthleteView;
