import React, { useMemo, useState, useEffect } from 'react';
import GymRowStyles from './GymRow.module.scss';
import StyleSheet from './Gyms.module.scss';
import Person4OutlinedIcon from '@mui/icons-material/Person4Outlined';
import EmptyState from '../composites/EmptyState';
import DashboardContentWrapper from '../composites/DashboardContentWrapper';
import GymRow from './GymRow';
import ListContent from '../composites/ListContent';
import { fetchAllGym } from '../../api';
// import { useRefreshState } from '../../providers';
import GymSettings from '../GymSettings/GymSettings';
import { CircularProgress } from '@mui/material';

import type { Gym } from '../../types';

const gymListTitles = ['Gym Name', 'Logo', 'Options'];

const Gyms: React.FC = () => {
  const [gyms, setGyms] = useState<Gym[] | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchGyms = async () => {
      setLoading(true);
      const gyms = await fetchAllGym();
      setGyms(gyms);
      setLoading(false);
    };
    fetchGyms();
  }, []);

  const fetchAndSetGyms = async () => {
    setLoading(true);
    const gyms = await fetchAllGym();
    setGyms(gyms);
    setLoading(false);
  };

  const [selectedGym, setSelectedGym] = useState<string | null>(null);

  // const refreshState = useRefreshState();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const filteredGyms = useMemo(() => {
    if (!gyms) return [];
    const searchQueryRegex = new RegExp(searchQuery.toLowerCase());
    return gyms
      .filter((gym: Gym) => searchQueryRegex.test(gym.name.toLowerCase()))
      .sort(
        (a, b) =>
          a.name?.toLowerCase().localeCompare(b.name?.toLowerCase() || '') || 0,
      );
  }, [searchQuery, gyms]);

  const renderEmptyState = () => (
    <EmptyState icon={<Person4OutlinedIcon />} text="No Gyms Have Been Added" />
  );

  const renderContent = () => {
    if (loading)
      return (
        <div className={StyleSheet.loading}>
          <CircularProgress />;
        </div>
      );
    return (
      <ListContent
        items={filteredGyms}
        listHeaderClassName={GymRowStyles.listGrid}
        EmptyStateComponent={renderEmptyState}
        titles={gymListTitles}
        ItemComponent={({ data }) => (
          <GymRow
            gym={data}
            selected={selectedGym === data.id}
            onSelect={() => setSelectedGym(data.id)}
            key={data.id}
          />
        )}
      />
    );
  };

  const getDrillInContent = () => {
    if (!selectedGym) return null;
    const selectedGymData = gyms?.find((gym) => gym.id === selectedGym);
    return (
      <GymSettings
        gym={selectedGymData}
        onClose={async () => {
          await fetchAndSetGyms();

          setSelectedGym(null);
        }}
      />
    );
  };

  return (
    <DashboardContentWrapper
      searchPlaceholder="Search for Gym"
      title={
        selectedGym
          ? `${gyms?.find((gym) => gym.id === selectedGym)?.name}`
          : 'Gyms'
      }
      drillInContent={getDrillInContent()}
      setSearchQuery={setSearchQuery}
      actions={[]}
      onBack={() => {
        setSelectedGym(null);
      }}
    >
      {renderContent()}
    </DashboardContentWrapper>
  );
};

export default Gyms;
